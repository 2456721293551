import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import tableHeaderStyle from "../../Assets/jss/tableHeaderStyle";

const useStyles = makeStyles(tableHeaderStyle);

function TableHeader({ handleSort, orderBy, order, setOrder }) {
  const classes = useStyles();

  const createSortHandler = (id) => {
    handleSort(id);
    setOrder(order === "asc" ? "desc" : "asc");
  };
  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell className={classes.tableRow} align="center"></TableCell>
        <TableCell className={classes.tableRow} align="center">
          <TableSortLabel
            active={orderBy === "formSubmittedOn"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("formSubmittedOn")}
          >
            Submitted on (MM/DD/YYYY)
          </TableSortLabel>
        </TableCell>
        <TableCell
          className={classes.tableRow}
          align="center"
          style={{ fontSize: "15px" }}
        >
          <TableSortLabel
            active={orderBy === "firstName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("firstName")}
          >
            First Name
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          <TableSortLabel
            active={orderBy === "lastName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("lastName")}
          >
            Last Name
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          <TableSortLabel
            active={orderBy === "dob"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("dob")}
          >
            DOB (MM/DD/YYYY)
          </TableSortLabel>
        </TableCell>

        <TableCell
          className={classes.tableRow}
          align="center"
          style={{ width: "3vw" }}
        >
          <TableSortLabel>Status & Action</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
