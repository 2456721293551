import React from "react";

import footerlogo from "../../Assets/header.svg";
import logout from "../../Assets/logout.png";
import Headerlogo from "../../Assets/Polaris Logo.svg";
import Headerlogo1 from "../../Assets/logo1.png";
import "./header.css";
import { useHistory } from "react-router-dom";

export default function Header() {
  const history = useHistory();

  const logoutFun = () => {
    localStorage.clear();
    history.push({
      pathname: "/",
    });
  };
  return (
    <>
      {localStorage.getItem("features") === "Vax PDF Module" ? (
        <div className="header" style={{ justifyContent: "space-between" }}>
          <div>
            <img src={footerlogo} className="headerlogo" />
          </div>
          <div>
            <img
              src={logout}
              className="logout"
              title="Logout"
              onClick={logoutFun}
            />
          </div>
        </div>
      ) : (
        <div className="header1">
          <div>
            <img src={Headerlogo} className="headerlogo" />
          </div>
          <div>
            <img src={Headerlogo1} className="headerlogo1" />
          </div>
        </div>
      )}
    </>
  );
}
