import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import "./Login.css";
import "date-fns";
import Loginbg from "../../Assets/bg_image.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
// import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header.js";
import * as API from "../../Service/API/index.js";

export default function Login() {
  const history = useHistory();
  const [emailId, setemailId] = React.useState("");
  const [loader, setloader] = React.useState(false);
  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");
  const mobileNumberChange = (number) => {
    setNumber(number);
  };
  const [emailerror, setemailError] = React.useState(false);
  const [emailvaliderror, setemailvalidError] = React.useState(false);
  const [emailreserror, setemailresError] = React.useState(false);

  const handleemailId = (e) => {
    setemailId(e.target.value);
  };
  const formSubmit = () => {
    if (emailId.trim() === "") {
      setemailError(true);
      setemailresError(false);
      return 0;
    } else if (emailId) {
      if (
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
          emailId.trim()
        ) == false &&
        emailId.trim() != ""
      ) {
        setemailError(false);
        setemailvalidError(true);
        setemailresError(false);

        return 0;
      } else {
        APIcall();
      }
    } else {
      APIcall();
    }
  };
  const APIcall = () => {
    setloader(true);
    setemailError(false);
    setemailvalidError(false);
    let data = {
      email: emailId.trim(),
    };

    API.LoginAPI(data, (res) => {
      if (res.statusCode == "200") {
        data.from = "login";
        history.push({
          pathname: "/login-otp",
          state: { ...data, res },
        });
      } else {
        setloader(false);
        setemailresError(true);
      }
    });
  };
  const SignUp = () => {
    history.push({
      pathname: "/signup",
    });
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      formSubmit();
    }
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <Header />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="containerHolder1">
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={12} md={6} sm={12}>
              <img src={Loginbg} className="loginBg" />
            </Grid>

            <Grid item xs={12} md={6} sm={12} style={{ marginTop: "-50px" }}>
              <h1 className="login_h1">VIDOH Immunization Review Login</h1>

              <div className="accordionbg1">
                <div className="accSummary1">
                  <Grid container spacing={3} className="loginGrid">
                    <Grid item xs={12} md={12} sm={12}>
                      <TextField
                        id="outlined-basic"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        variant="outlined"
                        label="Email Id"
                        value={emailId}
                        onChange={handleemailId}
                        onKeyUp={SignInC}
                      />
                      {emailerror === true ? (
                        <div
                          className="errormess"
                          style={{ textAlign: "center" }}
                        >
                          Please enter email-id
                        </div>
                      ) : emailvaliderror === true ? (
                        <div
                          className="errormess"
                          style={{ textAlign: "center" }}
                        >
                          Please enter valid email-id
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    {/* <Grid item xs={12} md={12} sm={12} style={{ textAlign: "center", padding: '0px' }}>
                      (OR)
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="loginmobile">
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ paddingBottom: "0.3em" }}
                      >
                        Mobile Number
                      </InputLabel>
                      <PhoneInput
                        country={"us"}
                        onlyCountries={["us"]}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        onChange={mobileNumberChange}
                        value={newnumber}
                        className="loginmobile"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="loginmobile">
                      <span> Don't have an account?</span>
                      <span className="prevQues" onClick={SignUp}>{" "} Sign Up
                      </span>
                    </Grid> */}

                    <Grid item xs={12} md={12} sm={12}>
                      <div className="submit" style={{ paddingTop: "0px" }}>
                        <button className="loginSubm" onClick={formSubmit}>
                          Next
                        </button>
                      </div>
                      {emailreserror ? (
                        <div
                          className="errormess"
                          style={{ textAlign: "center", marginTop: "30px" }}
                        >
                          Email address has not been registered
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
