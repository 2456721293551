import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Grid,
  MenuItem,
  Select,
  TextField,
  TablePagination,
  CircularProgress,
  InputLabel,
  FormControl,
  Divider,
  ListSubheader,
} from "@material-ui/core";
import Row from "./Row";
import * as API from "../../Service/API/index.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { toast, ToastContainer } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import tableHeaderStyle from "../../Assets/jss/tableHeaderStyle";
import moment from "moment";

const useStyles1 = makeStyles(tableHeaderStyle);
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 10,
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ApprovedPatient() {
  const classes1 = useStyles1();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loader, setloader] = React.useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("formSubmittedOn");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = React.useState("");
  const [firstNamesearch, setsearchfirst] = React.useState("");
  const [lastNamesearch, setsearchlast] = React.useState("");
  const [totalcount, setTotalCount] = React.useState("");
  const [FilterState, setFilterState] = React.useState("");
  const [FilterCountry, setFilterCountry] = React.useState("");
  const [stateSelect, setstateSelect] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [FilterLab, setFilterLab] = React.useState("");
  const [labName, setlabName] = React.useState([]);
  const [vaccineNames, setvaccineNames] = React.useState([]);
  const [searchDob, setsearchDob] = React.useState(null);
  const [stateName, setstateName] = React.useState("");
  const [labOrgName, setlabOrgName] = React.useState("");
  const [countryName, setCountryName] = React.useState("");
  const [featureLab, setfeatureLab] = React.useState(
    localStorage.getItem("labids")
      ? localStorage.getItem("labids").split(",")
      : []
  );

  const [featurestate, setfeaturestate] = React.useState(
    localStorage.getItem("states")
      ? localStorage.getItem("states").split(",")
      : []
  );

  const [featurecountry, setfeaturecountry] = React.useState(
    localStorage.getItem("country")
      ? localStorage.getItem("country").split(",")
      : []
  );

  const [SelectedSites, setSelectedSites] = React.useState([]);
  const [statusvalue, setStatusvalue] = React.useState([
    "Approved/VAMS Pending",
    "Approved/VAMS Updated",
    "Document Approval",
    "ID Verified/Registry Match"]);

  //Expand collapse states
  const [expandedId, setExpandedId] = React.useState(-1);
  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  let history = useHistory();
  let arrayvalue = [];
  let mergeLab = false;
  if (featureLab.length !== 0) {
    for (let i = 0; i < labName.length; i++) {
      for (let j = 0; j < featureLab.length; j++) {
        if (labName[i].labId === parseInt(featureLab[j])) {
          if (labName[i].labId === 26 || labName[i].labId === 27) {
            if (mergeLab === false) {
              mergeLab = true;
              labName[i].organizationName = "Frederiksted";
              arrayvalue.push(labName[i]);
            }
          } else {
            arrayvalue.push(labName[i]);
          }
        }
      }
    }
  } else {
    for (let i = 0; i < labName.length; i++) {
      if (labName[i].labId === 26) {
        labName[i].organizationName = "Frederiksted";
        arrayvalue.push(labName[i]);
      } else {
        if (labName[i].labId !== 27) {
          arrayvalue.push(labName[i]);
        }
      }
    }
  }

  let arrayvaluestate = [];
  if (featurestate.length !== 0) {
    for (let i = 0; i < stateSelect.length; i++) {
      for (let j = 0; j < featurestate.length; j++) {
        if (stateSelect[i][1].stateCode === featurestate[j]) {
          arrayvaluestate.push(stateSelect[i]);
        }
      }
    }
  } else {
    for (let i = 0; i < stateSelect.length; i++) {
      arrayvaluestate.push(stateSelect[i]);
    }
  }

  let arrayvaluecountry = [];
  if (featurecountry.length !== 0) {
    for (let i = 0; i < countryList.length; i++) {
      for (let j = 0; j < featurecountry.length; j++) {
        if (countryList[i].countryCode === featurecountry[j]) {
          arrayvaluecountry.push(countryList[i]);
        }
      }
    }
  } else {
    for (let i = 0; i < countryList.length; i++) {
      arrayvaluecountry.push(countryList[i]);
    }
  }

  const getlist = () => {
    setloader(true);
    setData([]);
    setExpandedId(-1);
    let data = {
      limit: 10,
      pageNo: page,
      sortBy: orderBy ? orderBy : "formSubmittedOn",
      status: statusvalue,
      formSubmittedOn: null,
      state: FilterState ? FilterState : null,
      labId: sitearray.length ? sitearray : [],
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      sortIn: order,
    };
    API.GETEXISTINGPATIENT(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        toast.error(res.errorMessage);
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  const GetCounties = () => {
    API.GetCounties_API((res) => {
      let statelist = [];
      statelist.push(...Object.entries(res.info.states), ["other"]);
      setstateSelect(statelist);
    });
  };

  const GetVaccinationConfigs = () => {
    API.GetOrg_API((res) => {
      setlabName(res.info);
    });
  };

  const GetVaccinationNames = () => {
    API.GetVaccination_API((res) => {
      setvaccineNames(res.info);
    });
  };

  //======Get CountryList======//
  const GetCountryList = () => {
    API.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };

  const onSelectChange = (event) => {
    setSelectedSites([...event]);
  };
  const onRemoveChange = (event) => {
    setSelectedSites([...event]);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getlist();
      // GetCounties();
      GetVaccinationConfigs();
      GetVaccinationNames();
      GetCountryList();
    } else {
      history.push("/");
    }
  }, [page]);

  const FilterStateChange = (e) => {
    setFilterState(e.target.value);
    let laborgname = arrayvaluestate.filter((item) => {
      if (item[1].stateCode === e.target.value) {
        setstateName(item[0]);
      } else if ("other" === e.target.value) {
        setstateName("Outside of U.S");
      }
    });
  };

  const FilterLabChange = (e) => {
    setFilterLab(e.target.value);

    let laborgname = arrayvalue.filter((item) => {
      if (item.labId === e.target.value) {
        setlabOrgName(item.organizationName);
      }
    });
  };

  const FilterCountryOnchange = (e) => {
    if (e.target.value !== "") {
      setFilterCountry(e.target.value);
      countryList.map((item) => {
        if (item.countryCode == e.target.value) {

          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              setFilterState("");
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setstateSelect(sortedEntries);
            }
          });
        } else {
          setstateSelect([]);
        }
      });

      let countryorgname = countryList.filter((item) => {
        if (item.countryCode === e.target.value) {
          setCountryName(item.name);
        }
      });
    } else {
      setFilterCountry(e.target.value);
      setstateSelect([]);
      setFilterState("");
    }
  };


  const createSortHandler = (id) => {
    setOrderBy(id);
    setOrder(order === "asc" ? "desc" : "asc");
    setloader(true);
    setData([]);
    setExpandedId(-1);
    let data = {
      limit: 10,
      pageNo: page,
      sortBy: id,
      status: statusvalue,
      state: FilterState ? FilterState : null,
      labId: sitearray.length ? sitearray : [],
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      formSubmittedOn: null,
      sortIn: order === "asc" ? "desc" : "asc",
    };
    API.GETEXISTINGPATIENT(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  const handleChange = (e) => {
    setValues(e.target.value);
    setStatusvalue([e.target.value]);
    setPage(0);
    setloader(true);
    setData([]);
    setExpandedId(-1);
    let data = {
      limit: 10,
      pageNo: page,
      sortBy: orderBy ? orderBy : "formSubmittedOn",
      status: [e.target.value],
      state: FilterState ? FilterState : null,
      labId: sitearray.length ? sitearray : [],
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      formSubmittedOn: null,
      sortIn: order,
    };
    API.GETEXISTINGPATIENT(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  //----SearchBy handle change----//
  const searchHandleChange = (e) => {
    setsearchfirst(e.target.value);
  };
  const searchHandlelast = (e) => {
    setsearchlast(e.target.value);
  };
  const searchDobHandleChange = (date) => {
    setsearchDob(date);
  };

  let sitearray = [];
  if (SelectedSites.length) {
    SelectedSites.map((item) => {
      if (item.labId === 26) {
        sitearray.push(item.labId);
        sitearray.push(27);
      } else if (item.labId === 27) {
        sitearray.push(26);
        sitearray.push(item.labId);
      } else {
        sitearray.push(item.labId);
      }
    });
  }

  //---Searchby onClick ---//
  const SearchByValue = (e) => {
    if (FilterCountry !== "" && FilterState === "") {
      toast.error("Please select the location");
    } else if (
      firstNamesearch.trim() === "" &&
      lastNamesearch.trim() === "" &&
      searchDob === null &&
      FilterState === "" &&
      sitearray.length === 0
    ) {
      toast.error("Please enter the search value");
    } else {
      setPage(0);
      setData([]);
      console.log("API CALl");
      console.log("siteArray", sitearray);
      getlist();
    }
  };

  //--Pagination onchange--//
  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setData([]);
    setPage(newPage);
  };
  const Resetbyvalue = () => {
    setPage(0);
    setTotalCount("0");
    setloader(true);
    setsearchfirst("");
    setsearchlast("");
    setsearchDob(null);
    setFilterState("");
    setFilterCountry("");
    setFilterLab("");
    setValues("");
    setSelectedSites([]);
    setstateSelect([]);
    setExpandedId(-1);
    setStatusvalue([
      "Approved/VAMS Pending",
      "Approved/VAMS Updated",
      "Document Approval",
      "ID Verified/Registry Match"]);
    setOrder("desc");
    setOrderBy("formSubmittedOn");
    setData([]);
    let data = {
      limit: 10,
      pageNo: 0,
      sortBy: "formSubmittedOn",
      status: [
        "Approved/VAMS Pending",
        "Approved/VAMS Updated",
        "Document Approval",
        "ID Verified/Registry Match"],
      labId: [],
      state: null,
      firstName: null,
      lastName: null,
      dob: null,
      formSubmittedOn: null,
      sortIn: "desc",
    };
    API.GETEXISTINGPATIENT(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalListCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };
  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      SearchByValue();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={4000} />
      <div className="header_title">USVI PolarisKey</div>
      <Divider />
      <div className="module_title">Approved Patient Module</div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12} className="label_row">
          <div className="search_label">Search by:</div>
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <TextField
            label="First name"
            type="text"
            value={firstNamesearch}
            name="firstName"
            onChange={searchHandleChange}
            onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <TextField
            label="Last name"
            type="text"
            value={lastNamesearch}
            name="lastName"
            onChange={searchHandlelast}
            onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{
                width: "100%",
              }}
              clearable
              onKeyUp={SignInC}
              value={searchDob}
              onChange={(date) => searchDobHandleChange(date)}
              format="MM/dd/yyyy"
              maxDate={new Date()}
              autoOk
              label={
                <Typography variant="inherit" component="span">
                  DOB <span className="adjs">(MM/DD/YYYY) </span>
                </Typography>
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3} sm={12} style={{ marginTop: "-2px" }}>
          <FormControl
            variant="standard"
            className="form-dropdown form_search_field"
          >
            <InputLabel>Country</InputLabel>
            <Select
              value={FilterCountry}
              name="FilterCountry"
              onChange={FilterCountryOnchange}
              title={countryName}
              required
            >
              <MenuItem style={{ height: "25px" }} value={""}> </MenuItem>
              {arrayvaluecountry.map((item) => (
                <MenuItem key={item.id} value={item.countryCode}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} sm={12} style={{ marginTop: "-2px" }}>
          <FormControl
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-simple-select-label">Location</InputLabel>
            <Select
              disabled={!stateSelect.length > 0}
              value={FilterState}              
              name="FilterState"
              onChange={FilterStateChange}
              title={stateName}
              required
            >
              {arrayvaluestate.map((item) => (
                <MenuItem value={item[1].stateCode}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="filter_lebel" style={{ marginTop: '15px' }}>Filter by:</div>
      <div className="filter_row" >
        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={6} sm={12}>
            <Multiselect
              selectedValues={SelectedSites}
              options={arrayvalue}
              isObject={true}
              displayValue="organizationName"
              placeholder="Sites"
              onSelect={onSelectChange}
              onRemove={onRemoveChange}
              showCheckbox
            />
          </Grid>
          <Grid item xs={12} md={3} sm={12}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values}
                displayEmpty
                onChange={handleChange}
              >
                <ListSubheader style={{ pointerEvents:"none", fontStyle: "italic" }}>- Approval Statuses -</ListSubheader>
                <MenuItem value={"Approved/VAMS Pending"}>Approved/VAMS Pending</MenuItem>
                <MenuItem value={"Approved/VAMS Updated"}>Approved/VAMS Updated</MenuItem>
                <MenuItem value={"Document Approval"}>Document Approval</MenuItem>
                <MenuItem value={"ID Verified/Registry Match"}>ID Verified/Registry Match</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
          marginBottom: "20px",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <button className="srchbtn" onClick={SearchByValue}>
            Search
          </button>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <button className="srchbtn" onClick={Resetbyvalue}>
            Reset
          </button>
        </div>
      </div>
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : data.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className={classes1.root}>
              <TableRow>
                <TableCell
                  className={classes1.tableRow}
                  align="center"
                ></TableCell>
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel
                    active={orderBy === "formSubmittedOn"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("formSubmittedOn")}
                  >
                    Submitted on (MM/DD/YYYY)
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel
                    active={orderBy === "firstName"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("firstName")}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel
                    active={orderBy === "lastName"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("lastName")}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes1.tableRow}
                  align="center"
                  style={{ width: "19vw" }}
                >
                  <TableSortLabel
                    active={orderBy === "dob"}
                    direction={order === "asc" ? "asc" : "desc"}
                    onClick={() => createSortHandler("dob")}
                  >
                    DOB (MM-DD-YYYY)
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  className={classes1.tableRow}
                  align="center"
                  style={{ width: "3vw" }}
                >
                  Status & Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((dataItems) => {
                return (
                  <Row
                    key={dataItems.patientId}
                    keyvalue={dataItems.patientId}
                    dataItems={dataItems}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    handleExpandClick={handleExpandClick}
                    stateSelect={stateSelect}
                    labName={labName}
                    vaccineNames={vaccineNames}
                    countryList={countryList}
                    getlist={getlist}
                    setloader={setloader}
                  />
                );
              })}
            </TableBody>
          </Table>
          {totalcount > 10 ? (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalcount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : (
            ""
          )}
        </TableContainer>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} align="center">
            <Typography>No Records Found</Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
