import React, { useEffect, useState } from "react";
import "./SideNav.css";
import sidenavlogo from "../../Assets/page10/menu_logo.png";
// import navlogo from "../../Assets/Logo-Icon.png";
import navlogo from "../../Assets/Google-Pass.png";
import profilepic from "../../Assets/page10/profile_image.png";
import Newpatient from "../NewPatient/Table.js";
import TestingPatient from "../Testing/Table.js";
import ExistingPatient from "../ExistingPatient/Table.js";
import ApprovedPatient from "../ApprovedPatient/Table";
import close from "../../Assets/close.png";
import menuicon1 from "../../Assets/page10/menu_icon4.png";
import menuicon1active from "../../Assets/page10/menu_icon9.png";
import ActiveIcon from "../../Assets/ActiveIcon.png";
import InactiveIcon from "../../Assets/InactiveIcon.png";
import ActiveIconTesting from "../../Assets/ActiveIconTesting.png";
import InactiveIconTesting from "../../Assets/InactiveIconTesting.png";
import missingActiveIcon from "../../Assets/MissingActiveIcon.png";
import missingInActiveIcon from "../../Assets/MissingInactiveIcon.png";
import ApproveActiveIcon from "../../Assets/ApproveActiveIcon.png";
import ApproveInactiveIcon from "../../Assets/ApproveInactiveIcon.png";
import LogoutIcon from "../../Assets/LogoutIcon.png";
import logout from "../../Assets/logout.png";
import FeedbackIcon from "../../Assets/FeedbackIcon.png";
import { slide as Menu } from "react-burger-menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@material-ui/core/IconButton";
import "./burger.css";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import MissingDose from "../MissingDose/Table.js";

// import Welcome from "../Welcome/welcome";
// import TestRecord from "../TestingRecord/testingrecord.js";

export default function SideNav() {
  // var isActive = this.context.router.route.location.pathname;
  let history = useHistory();

  const [isactivemenu1, setActivemenu1] = useState(true);
  const [isactivemenu2, setActivemenu2] = useState(false);
  const [isactivemenu3, setActivemenu3] = useState(false);
  const [isactivemenu4, setActivemenu4] = useState(false);
  const [isactivemenu5, setActivemenu5] = useState(false);

  const [changeValue, setchangeValue] = React.useState(
    localStorage.getItem("features")
  );

  useEffect(() => {
    if (localStorage.getItem("features") == null) {
      history.push("/");
      console.log("out");
    }

    if (window.location.pathname === "/new-patient") {
      setActivemenu1(true);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(false);
    } else if (window.location.pathname === "/existing-patient") {
      setActivemenu1(false);
      setActivemenu2(true);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(false);
    } else if (window.location.pathname === "/missing-dose") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(true);
      setActivemenu4(false);
      setActivemenu5(false);
    } else if (window.location.pathname === "/testing-patient") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(true);
      setActivemenu5(false);
    } else if (window.location.pathname === "/approved-patient") {
      setActivemenu1(false);
      setActivemenu2(false);
      setActivemenu3(false);
      setActivemenu4(false);
      setActivemenu5(true);
    }
  }, [localStorage.getItem("features")]);

  const routes = [
    {
      path: "/new-patient",
      exact: true,
      // sidebar: () => <div>home!</div>,
      main: () => (
        <>
          <Newpatient />
        </>
      ),
    },
    {
      path: "/existing-patient",
      exact: true,
      // sidebar: () => <div>home!</div>,
      main: () => (
        <>
          <ExistingPatient />
        </>
      ),
    },

    {
      path: "/missing-dose",
      exact: true,
      // sidebar: () => <div>home!</div>,
      main: () => (
        <>
          <MissingDose />
        </>
      ),
    },
    {
      path: "/testing-patient",
      exact: true,
      // sidebar: () => <div>home!</div>,
      main: () => (
        <>
          <TestingPatient />
        </>
      ),
    },
    {
      path: "/approved-patient",
      exact: true,
      // sidebar: () => <div>home!</div>,
      main: () => (
        <>
          <ApprovedPatient />
        </>
      ),
    },
  ];

  const loadMenu1 = () => {
    setActivemenu1(true);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(false);
  };

  const loadMenu2 = () => {
    setActivemenu1(false);
    setActivemenu2(true);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(false);
  };

  const loadMenu3 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(true);
    setActivemenu4(false);
    setActivemenu5(false);
  };
  const loadMenu4 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(true);
    setActivemenu5(false);
  };
  const loadMenu5 = () => {
    setActivemenu1(false);
    setActivemenu2(false);
    setActivemenu3(false);
    setActivemenu4(false);
    setActivemenu5(true);
  };

  const [getLogout, setLogout] = React.useState(false);
  const Logout = () => {
    setLogout(true);
  };
  const Logout_req = () => {
    history.push({
      pathname: "/",
    });
    localStorage.clear();
  };
  const handleClose = () => {
    setLogout(false);
  };
  return (
    <>
      {getLogout ? (
        <div
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modalBox"
        >
          <div className="modal-content">
            <img src={close} className="closeIcn" onClick={handleClose} />
            <div className="btnAdjHeader">Are you sure you want to logout?</div>
            <div className="btnAdjHeader">
              <button className="subBtn10" onClick={Logout_req}>
                Yes
              </button>

              <button className="subBtn10" onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{ backgroundColor: "#FFF", zIndex: "99" }}
        className="headermobile"
      >
        <div style={{ marginTop: "-30px" }} className="mobileLogo">
          <img src={navlogo} width="60px" style={{ paddingRight: "5px" }} />
        </div>
        <div className="mobileMenu">
          <Menu right>
            {changeValue && changeValue.includes("Approval Module(edit)") || changeValue && changeValue.includes("Approval Module(view)") ? (
              <Link to="/approved-patient" id="about" className="menu-item">
                Approved Patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Patient Module") ? (
              <Link to="/existing-patient" id="about" className="menu-item">
                Existing patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Form Module") ? (
              <Link to="/new-patient" id="home" className="menu-item">
                New patient
              </Link>
            ) : (
              ""
            )}
            {changeValue && changeValue.includes("Vax Missing Dose Module") ? (
              <Link to="/missing-dose" id="missing" className="menu-item">
                Missing Dose
              </Link>
            ) : (
              ""
            )}
            {/*             {changeValue && changeValue.includes("Vax Form Module") ? (
              <Link to="/testing-patient" id="testing" className="menu-item">
                Testing Patient
              </Link>
            ) : (
              ""
            )} */}


            <Link to="/" id="about" className="menu-item">
              Logout
            </Link>
          </Menu>
        </div>
      </div>

      <Router>
        <div className="mainSideLeft">
          <div className="inner">
            <div className="leftSideNav">
              {/* <div className="profileNav">
                <div>
                  <img src={sidenavlogo} />
                </div>
              </div> */}
              <div>
                <div className="dropdown">
                  <div className="dropbtn">
                    <img src={navlogo} width="60px" />
                  </div>
                  {/* <div className="dropdown-content">
                    <div onClick={Logout}>Logout1</div>
                  </div> */}
                </div>
              </div>

              <div
                className="menuIcons"
              // style={{
              //   height: "200px",
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-evenly",
              // }}
              >
                {changeValue && changeValue.includes("Approval Module(edit)") || changeValue && changeValue.includes("Approval Module(view)") ? (
                  <NavLink
                    to="/approved-patient"
                    exact
                    //   activeClassName="selectedDash"

                    onClick={loadMenu5}
                    className="LinksDash LinksDashadj"
                  //   isActive={

                  //   Setactive1(true)
                  //    }
                  >
                    {isactivemenu5 === true ? (
                      <img
                        src={ApproveActiveIcon}
                        alt="Approved_Patient"
                        title="Approved Patient"
                      />
                    ) : (
                      <img
                        src={ApproveInactiveIcon}
                        alt="Approved_Patient"
                        title="Approved Patient"
                      />
                    )}
                  </NavLink>
                ) : (
                  ""
                )}

                {changeValue && changeValue.includes("Vax Patient Module") ? (
                  <NavLink
                    to="/existing-patient"
                    exact
                    //   activeClassName="selectedDash"
                    className="LinksDash"
                    onClick={loadMenu2}
                  >
                    {isactivemenu2 === true ? (
                      <img
                        src={ActiveIcon}
                        alt="Home"
                        title="Existing patient approval"
                      />
                    ) : (
                      <img
                        src={InactiveIcon}
                        alt="Home"
                        title="Existing patient approval"
                      />
                    )}
                  </NavLink>
                ) : (
                  ""
                )}
                {changeValue && changeValue.includes("Vax Form Module") ? (
                  <NavLink
                    to="/new-patient"
                    exact
                    //   activeClassName="selectedDash"

                    onClick={loadMenu1}
                    className="LinksDash LinksDashadj"
                  //   isActive={

                  //   Setactive1(true)
                  //    }
                  >
                    {isactivemenu1 === true ? (
                      <img
                        src={menuicon1active}
                        alt="Home"
                        title="New patient approval"
                      />
                    ) : (
                      <img
                        src={menuicon1}
                        alt="Home"
                        title="New patient approval"
                      />
                    )}
                  </NavLink>
                ) : (
                  ""
                )}

                {changeValue &&
                  changeValue.includes("Vax Missing Dose Module") ? (
                  <NavLink
                    to="/missing-dose"
                    exact
                    //   activeClassName="selectedDash"

                    onClick={loadMenu3}
                    className="LinksDash LinksDashadj"
                  //   isActive={

                  //   Setactive1(true)
                  //    }
                  >
                    {isactivemenu3 === true ? (
                      <img
                        src={missingActiveIcon}
                        alt="Home"
                        title="Missing Dose"
                      />
                    ) : (
                      <img
                        src={missingInActiveIcon}
                        alt="Home"
                        title="Missing Dose"
                      />
                    )}
                  </NavLink>
                ) : (
                  ""
                )}

                {/* {changeValue && changeValue.includes("Vax Form Module") ? (
                  <NavLink
                    to="/testing-patient"
                    exact
                    //   activeClassName="selectedDash"

                    onClick={loadMenu4}
                    className="LinksDash LinksDashadj"
                    //   isActive={

                    //   Setactive1(true)
                    //    }
                  >
                    {isactivemenu4 === true ? (
                      <img
                        src={ActiveIconTesting}
                        alt="Home"
                        title="testing patient"
                      />
                    ) : (
                      <img
                        src={InactiveIconTesting}
                        alt="Home"
                        title="testing patient"
                      />
                    )}
                  </NavLink>
                ) : (
                  ""
                )} */}

              </div>
              <div
                style={{
                  position: "absolute",
                  left: "10px",
                }}
              >
                <IconButton
                  aria-label="logout"
                  style={{ color: "#FFF" }}
                  onClick={Logout}
                  disableRipple={true}
                  disableFocusRipple={true}
                >
                  <ExitToAppIcon style={{ width: "1.3em", height: "1.2em" }} />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="mainRightFlex">
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </div>
        </div>
        <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
      </Router>
    </>
  );
}
