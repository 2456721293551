import React, { useState } from 'react'
import '../../Slider/Slider.css'
import BtnSlider from '../../Slider/BtnSlider'
import { toast } from 'react-toastify'
import PDFImageLogo from '../../Slider/icons/PDFImageLogo.png'
import PDFLogo from '../../Slider/icons/PDFLogo.png'
import Modal from '@material-ui/core/Modal'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import Closeicon from '../../Slider/icons/close.png'
import ImgSlide from './ImgSlide'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import DeleteIcon from '@mui/icons-material/Delete'
import * as API from '../../../Service/API/index.js'

export default function PhotoSlider({ data, getlist }) {
  const [slideIndex, setSlideIndex] = useState(1)
  const [Modalopen, modalsetOpen] = React.useState(false)
  const [confirmModal, setConfirmModal] = React.useState(false)

  const nextSlide = (e) => {
    e.preventDefault()
    if (slideIndex !== data.length) {
      setSlideIndex(slideIndex + 1)
    } else if (slideIndex === data.length) {
      setSlideIndex(1)
    }
  }

  const prevSlide = (e) => {
    e.preventDefault()
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
    } else if (slideIndex === 1) {
      setSlideIndex(data.length)
    }
  }

  const moveDot = (index) => {
    setSlideIndex(index)
  }

  const [getImg, setImg] = React.useState('')
  const [id, setID] = React.useState('')

  const handleOpen = (imgURL, indexvalFromImage) => {
    if (imgURL.url != '') {
      setImg(imgURL.url)
      setID(imgURL.id)
      modalsetOpen(true)
    }
  }
  const handleClose = () => {
    modalsetOpen(false)
  }

  const handleDelete = () => {
    setConfirmModal(true)
  }

  const handleConfirm = () => {
    let data = {
      id: id,
      from: 'photoId',
    }

    API.deletephotoIdandVaxcard(data, (res) => {
      if (res.statusCode === 200) {
        toast.success('PhotoId deleted successfully')
        setConfirmModal(false)
        getlist()
      } else {
        toast.error(res.info)
      }
    })
  }

  const handleCancel = () => {
    setConfirmModal(false)
  }

  const body = (
    <div
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      className="paperModal"
      onContextMenu={(e) => {
        e.preventDefault()
      }}
    >
      <div className="closeHolder" onClick={handleClose}>
        <img src={Closeicon} style={{ width: '30px' }} alt="close-icon" />
      </div>

      {getImg.split('.').pop().split(/\#|\?/)[0].toLowerCase() === 'pdf' ? (
        <div className="pdfViewModal">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div>
              <Viewer defaultScale={0.5} fileUrl={getImg} />
            </div>
          </Worker>
        </div>
      ) : (
        <div className="modal_image_preview">
          <ReactPanZoom image={getImg} alt="modal-view" />
          {data.length > 1 ? (
            <button onClick={handleDelete}>
              <DeleteIcon className="delete-icon" /> Remove
            </button>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )

  const remove = (
    <div style={{ top: '35%' }} className="confirm-modal">
      <p
        style={{
          fontSize: '14px',
          padding: '10px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: '500',
        }}
      >
        This image will be permanently deleted. Please confirm you wish to
        remove this image.
      </p>
      <div className="confirm-alert" style={{ padding: '10px' }}>
        <button onClick={handleConfirm} className="confirm-button">
          Confirm
        </button>
        <button onClick={handleCancel} className="cancel-button">
          Cancel
        </button>
      </div>
    </div>
  )

  return (
    <>
      <div className="container-slider">
        {data.map((item, index) => {
          return (
            <ImgSlide
              slideIndex={slideIndex}
              handleOpen={handleOpen}
              indexval={index}
              imgurl={item}
              data={data}
            />
          )
        })}
        {data.length > 1 ? (
          <>
            <BtnSlider moveSlide={nextSlide} direction={'next'} />
            <BtnSlider moveSlide={prevSlide} direction={'prev'} />
          </>
        ) : (
          ''
        )}

        <div className="container-dots">
          {Array.from({ length: data.length }).map((item, index) => (
            <div
              onClick={() => moveDot(index + 1)}
              className={slideIndex === index + 1 ? 'dot active' : 'dot'}
            ></div>
          ))}
        </div>
      </div>
      <Modal
        open={Modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={confirmModal}
        onClose={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {remove}
      </Modal>
    </>
  )
}