// Auth
export const LOGIN_URL = process.env.REACT_APP_API_URL + "vax/admin/login";
export const VALIDATEOTP_URL =
  process.env.REACT_APP_API_URL + "vax/admin/otp/validate";
// export const RESENDOTP_URL = process.env.REACT_APP_API_URL + 'patient/exp/otp/resend'
export const REFRESHTOKEN_URL =
  process.env.REACT_APP_API_URL + "vax/refresh/token";
export const GETNEWPATIENT_URL =
  process.env.REACT_APP_API_URL + "vax/get/all/vax/public/form";
export const UPDATE_STATUS_URL =
  process.env.REACT_APP_API_URL + "vax/update/status";
export const UPDATE_FORM_URL =
  process.env.REACT_APP_API_URL + "vax/update/form";
export const ADD_NOTE_URL = process.env.REACT_APP_API_URL + "vax/add/note";

// Existing patient
export const EXISTING_PATIENT_URL =
  process.env.REACT_APP_API_URL + "vax/get/all/vax/patient";
export const EXISTING_STATUS_URL =
  process.env.REACT_APP_API_URL + "vax/update/patient/status";
export const EXISTING_PATIENT_UPDATE_FORM_URL =
  process.env.REACT_APP_API_URL + "vax/update/patient";

export const EXISTING_ADD_NOTE_URL =
  process.env.REACT_APP_API_URL + "vax/add/patient/note";

// pdf
export const PdfInfo_URL = process.env.REACT_APP_API_URL + "vax/add/pdf/report";

export const GET_COUNTIES_URL =
  process.env.REACT_APP_API_URL + "public/get/state/counties";
export const GET_ORG_URL =
  process.env.REACT_APP_API_URL + "public/get/organizations";
export const GET_VACCINATION_URL =
  process.env.REACT_APP_API_URL + "public/get/vaccination/configs";

export const GET_BVI_COUNTRYLIST = process.env.REACT_APP_API_URL + "bvi/country/list";
export const GET_BVI_STATELIST = process.env.REACT_APP_API_URL + "bvi/state/list";

export const GETMISSINGDOSE_URL =
  process.env.REACT_APP_API_URL + "vax/get/all/vax/patient/missing/dose";
export const MISSINGDOSE_UPDATE_FORM_URL =
  process.env.REACT_APP_API_URL + "vax/update/missing/doses";
export const MISSINGDOSE_ADD_NOTE_URL =
  process.env.REACT_APP_API_URL + "vax/add/missingDose/note";
export const MISSINGDOSE_STATUS_URL =
  process.env.REACT_APP_API_URL + "vax/update/missing/dose/status";

  //Delete Dose URL
export const DELETE_DOSE = process.env.REACT_APP_API_URL + "vax/delete/dose";

//Resend Notification URL   
export const RESEND_NOTIFICATION =
  process.env.REACT_APP_API_URL + "vax/resend/notification";

//Delete photo and vaxcard images URL

export const DELETE_PHOTO_VAXID =
  process.env.REACT_APP_API_URL + "vax/delete/image";