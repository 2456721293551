const tableHeaderStyle = {
  root: {
    backgroundColor: "#4cd3c5",
  },
  root1: {
    backgroundColor: "#fff",
  },
  tableRow: {
    color: "#FFF",
    fontSize: "15px",
  },
};
export default tableHeaderStyle;
