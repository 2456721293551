import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  InputLabel,
  Button,
  FormControl,
  FormLabel,
  Box,
  Collapse,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Switch from "react-switch";
import Closeicon from "../../Assets/close.png";
import Modal from "@material-ui/core/Modal";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { makeStyles } from "@material-ui/core/styles";
import tableStyle from "../../Assets/jss/tableHeaderStyle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "100vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(tableStyle);

function Row({
  setloader,
  dataItems,
  keyvalue,
  expandedId,
  setExpandedId,
  handleExpandClick,
}) {
  const classes = useStyles();

  const { formId, firstName, lastName, dob, status, formSubmittedOn } =
    dataItems;

  const [StatusValue, SetstatusValue] = React.useState("");
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cardUrl: "",
    licenceUrl: "",
    testType: "",
    testResult: "",
  });

  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");
  const [dobDate, setdobDate] = React.useState(null);
  const [Modalopen, modalsetOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const [testTypeDate, settestTypeDate] = React.useState(null);

  //Error states
  const [firstNameError, setfirstNameError] = React.useState(false);
  const [lastNameError, setlastNameError] = React.useState(false);
  const [dobError, setdobError] = React.useState(false);
  const [mobilenumberError, setmobilenumberError] = React.useState(false);
  const [mobilenumberDigitError, setmobilenumberDigitError] =
    React.useState(false);
  const [emailError, setemailError] = React.useState(false);
  const [emailFormatError, setemailFormatError] = React.useState(false);

  //====Error====//
  const [testTypeError, settestTypeError] = React.useState(false);
  const [testResultError, settestResultError] = React.useState(false);
  const [testDateError, settestDateError] = React.useState(false);

  // Toogle switch
  const [getcheckedA, setcheckedA] = React.useState(true);
  const handleChangeText = (event) => {
    setcheckedA(!getcheckedA);
  };

  //Onchange functionalities
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  const handleDobChange = (date) => {
    setdobDate(date);
  };

  const handleChangeDate = (date) => {
    settestTypeDate(date);
  };

  //------onClick Form Update--------//
  const updateData = (e) => {
    e.preventDefault();

    setfirstNameError(false);
    setlastNameError(false);
    setdobError(false);
    setmobilenumberError(false);
    setmobilenumberDigitError(false);
    setemailError(false);
    setemailFormatError(false);
    settestTypeError(false);
    settestResultError(false);
    settestDateError(false);

    if (details.firstName === "") {
      setfirstNameError(true);
    } else if (details.lastName === "") {
      setlastNameError(true);
    } else if (dobDate === "Invalid date" || dobDate === null) {
      setdobError(true);
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      setmobilenumberError(true);
    } else if (newnumber.slice(dialCode.length).length < 9) {
      setmobilenumberDigitError(true);
    } else if (
      details.email.trim() == "" &&
      details.email.trim().length === 0
    ) {
      setemailError(true);
    } else if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        details.email.trim()
      ) &&
      details.email.trim() != ""
    ) {
      setemailFormatError(true);
    } else if (details.testType == "") {
      settestTypeError(true);
    } else if (details.testResult === "") {
      settestResultError(true);
    } else if (testTypeDate === "Invalid date" || testTypeDate === null) {
      settestDateError(true);
    } else {
      console.log(">>>>>>> API call >>>>>>>>>>");
      toast.success("Details updated successfully");
      setExpandedId(-1);
    }
  };

  //------onSelect--------//
  const onSelect = (id, keyvalue) => {
    handleExpandClick(keyvalue);
    if (id) {
      setDetails({
        ...details,
        ...dataItems,
      });

      setNumber(dataItems.mobileNumber);
      setdobDate(moment(dataItems.dob));
      settestTypeDate(moment(dataItems.testDate));
    }
  };

  //===Status update functionalities===//
  const handleStatus = (e) => {
    SetstatusValue(e.target.value);
    toast.success("Status updated successfully");
  };

  const [getImg, setImg] = React.useState("");
  const handleOpen = (imgURL) => {
    if (imgURL != "") {
      setImg(imgURL);
      modalsetOpen(true);
    }
  };
  const handleClose = () => {
    modalsetOpen(false);
  };

  const body = (
    <div
      style={modalStyle}
      className="paperModal"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className="closeHolder" onClick={handleClose}>
        <img src={Closeicon} alt="close-icon" />
      </div>
      {getImg.split(".").pop().split(/\#|\?/)[0].toLowerCase() === "pdf" ? (
        <div className="pdfViewModal">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div>
              <Viewer defaultScale={0.5} fileUrl={getImg} />
            </div>
          </Worker>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            margin: "15% 7% 10%",
            overflow: "auto",
          }}
        >
          <img
            style={{ maxHeight: "400px" }}
            src={getImg}
            alt="modal-view"
            draggable="false"
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <TableCell
          onClick={() => onSelect(formId, keyvalue)}
          style={{ cursor: "pointer" }}
        >
          {(expandedId === keyvalue) == false ? (
            <NavigateNextIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(formId, keyvalue)}
        >
          {formSubmittedOn ? moment(formSubmittedOn).format("MM-DD-yyyy") : ""}
        </TableCell>

        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          component="th"
          scope="row"
          onClick={() => onSelect(formId, keyvalue)}
        >
          {firstName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(formId, keyvalue)}
        >
          {lastName}
        </TableCell>
        <TableCell
          style={{ cursor: "pointer" }}
          align="center"
          onClick={() => onSelect(formId, keyvalue)}
        >
          {moment(dob).format("MM-DD-yyyy")}
        </TableCell>

        <TableCell align="center">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              value={StatusValue ? StatusValue : status}
              onChange={handleStatus}
              className="select_res"
              inputProps={{
                name: "Status",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Pending Review"}>Pending Review</option>
              <option value={"Level 1 Approval"}>Level 1 Approval</option>
              <option value={"Lab Approval"}>Lab Approval</option>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expandedId === keyvalue} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <form className={classes.root1} autoComplete="off">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container spacing={1} className="">
                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="First name *"
                        type="text"
                        value={details.firstName}
                        name="firstName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "off",
                        }}
                      />

                      {firstNameError === true ? (
                        <div className="errorText">
                          Please enter the firstname
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="Last name *"
                        type="text"
                        value={details.lastName}
                        name="lastName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "off",
                        }}
                      />

                      {lastNameError === true ? (
                        <div className="errorText">
                          Please enter the lastName
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{
                            width: "100%",
                          }}
                          name="dob"
                          clearable
                          value={dobDate}
                          onChange={(date) => handleDobChange(date)}
                          format="MM/dd/yyyy"
                          label="DOB (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>

                      {dobError === true ? (
                        <div className="errorText">
                          Please enter the date of birth
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "0.3em",
                          fontSize: "13px",
                        }}
                      >
                        Mobile number:
                      </InputLabel>
                      <PhoneInput
                        // country={"us"}
                        // onlyCountries={["us"]}
                        // countryCodeEditable={false}
                        required
                        name="mobileNumber"
                        // disableDropdown={true}
                        onChange={mobileNumberChange}
                        value={newnumber}
                      />
                      {mobilenumberError === true ? (
                        <div className="errorText">
                          Please enter the Mobile Number
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {mobilenumberDigitError === true ? (
                        <div className="errorText">
                          Please enter the full Mobile Number
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>

                    <Grid
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <Switch
                        checked={getcheckedA}
                        onChange={handleChangeText}
                        name="getcheckedA"
                      />
                      <span
                        style={{
                          color: "#808080",
                          marginLeft: "10px",
                          fontSize: "15px",
                        }}
                      >
                        Can this number receive text messages?
                      </span>
                    </Grid>

                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="Email *"
                        type="text"
                        value={details.email}
                        name="email"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: "none",
                        }}
                      />

                      {emailError === true ? (
                        <div className="errorText">Please enter the Email</div>
                      ) : (
                        <div></div>
                      )}
                      {emailFormatError === true ? (
                        <div className="errorText">
                          Please enter the correct email
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{ textAlign: "center" }}
                          className="span_text"
                        >
                          Photo ID:
                        </div>
                        <div>
                          {details.licenceUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.licenceUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.licenceUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.licenceUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  style={{ width: "100px" }}
                                  src={details.licenceUrl}
                                  onClick={(e) => {
                                    handleOpen(details.licenceUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.licenceUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      style={{ marginTop: "20px" }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="span_text"
                          style={{ textAlign: "center" }}
                        >
                          Testing Record Photo:
                        </div>
                        <div>
                          {details.cardUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.cardUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  style={{ width: "100px" }}
                                  src={details.cardUrl}
                                  onClick={(e) => {
                                    handleOpen(details.cardUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.cardUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={12}
                      style={{ marginTop: "20px" }}
                    >
                      <FormControl
                        className="form-dropdown"
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Test Type*
                        </InputLabel>
                        <Select
                          name="testType"
                          value={details.testType}
                          onChange={onChange}
                          required
                        >
                          <MenuItem value={"Covid-19: BinaxNow"}>
                            Covid-19: BinaxNow
                          </MenuItem>
                          <MenuItem value={"Covid-19: ID NOW"}>
                            Covid-19: ID NOW
                          </MenuItem>
                          <MenuItem value={"Innova Rapid Antigen Test"}>
                            Innova Rapid Antigen Test
                          </MenuItem>
                          <MenuItem value={"RT-PCR"}>RT-PCR</MenuItem>
                          <MenuItem value={"Insti HIV-1/HIV-2 Antibody Test"}>
                            Insti HIV-1/HIV-2 Antibody Test
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {testTypeError ? (
                        <div className="errorText">
                          Please select the test type
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={12}
                      style={{ marginTop: "23px" }}
                    >
                      <FormLabel
                        component="legend"
                        style={{ fontSize: "12px" }}
                      >
                        Test Result *
                      </FormLabel>
                      <RadioGroup
                        name="testResult"
                        value={details.testResult}
                        onChange={onChange}
                      >
                        <div className="test_result">
                          <FormControlLabel
                            className="content_radio_btn"
                            value="Positive"
                            control={<Radio className="mx" />}
                            label="Positive"
                          />
                          <FormControlLabel
                            className="content_radio_btn"
                            value="Negative"
                            control={<Radio className="mx" />}
                            label="Negative"
                          />
                          <FormControlLabel
                            className=" content_radio_btn"
                            value="Invalid"
                            control={<Radio className="mx" />}
                            label="Invalid"
                          />
                        </div>
                      </RadioGroup>
                      {testResultError ? (
                        <div className="errorText">
                          Please select the test result
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={12}
                      style={{ marginTop: "20px" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ width: "100%" }}
                          clearable
                          value={testTypeDate}
                          onChange={(date) => handleChangeDate(date)}
                          format="MM/dd/yyyy"
                          label="Test date (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>

                      {testDateError ? (
                        <div className="errorText">
                          Please select the test date
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ marginLeft: "20px" }}>
                        <button
                          className="srchbtn"
                          onClick={(e) => updateData(e)}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Modal
        open={Modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
}

export default Row;
