import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Testing.css";
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  CircularProgress,
  TablePagination,
  Typography,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";
import DataList from "./Data";
import Row from "./Row";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import tableHeaderStyle from "../../Assets/jss/tableHeaderStyle";
const useStyles1 = makeStyles(tableHeaderStyle);

const TableContent = () => {
  const classes1 = useStyles1();

  const [data, setData] = useState(DataList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = React.useState("");
  const [firstNamesearch, setsearchfirst] = React.useState("");
  const [lastNamesearch, setsearchlast] = React.useState("");
  const [searchDob, setsearchDob] = React.useState(null);

  const [totalcount, setTotalCount] = React.useState();
  const [loader, setloader] = React.useState(false);

  //Expand collapse states
  const [expandedId, setExpandedId] = React.useState(-1);

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const searchDobHandleChange = (date) => {
    setsearchDob(moment(date).format("yyyy-MM-DD"));
  };

  const SearchByValue = () => {
    if (firstNamesearch == "" && lastNamesearch == "" && searchDob == null) {
      toast.error("please enter the search value ");
    } else if (
      firstNamesearch !== "" ||
      lastNamesearch !== "" ||
      searchDob !== null
    ) {
      let dataempty = [];
      DataList.filter((item) => {
        if (
          item.firstName.toLowerCase() === firstNamesearch.toLowerCase() ||
          item.lastName.toLowerCase() === lastNamesearch.toLowerCase() ||
          item.dob === searchDob
        ) {
          dataempty.push(item);
        }
      });
      setData(dataempty);
    }
  };

  const Resetbyvalue = () => {
    setloader(true);
    setsearchfirst("");
    setsearchlast("");
    setsearchDob(null);
    setValues("");
    setData(DataList);
    setloader(false);
  };

  const handleChange = (e) => {
    setValues(e.target.value);

    let dataempty = [];
    DataList.filter((item) => {
      if (item.status.toLowerCase() === e.target.value.toLowerCase()) {
        dataempty.push(item);
      }
    });
    setData(dataempty);
  };

  //--Pagination onchange--//
  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={2000} />
      <div className="header_title">USVI PolarisKey</div>
      <Divider />
      <div className="module_title">Testing Patient Approval</div>
      <div className="filter_row">
        <div className="search_filter">
          <div className="search_label">Search by:</div>
          <TextField
            className="testing_textfield"
            label="First name"
            type="text"
            value={firstNamesearch}
            name="firstName"
            onChange={(e) => setsearchfirst(e.target.value)}
            // onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "off",
            }}
          />
        </div>
        <div className="search_filter">
          <TextField
            className="testing_textfield"
            label="Last name"
            type="text"
            value={lastNamesearch}
            name="lastName"
            onChange={(e) => setsearchlast(e.target.value)}
            // onKeyUp={SignInC}
            inputProps={{
              maxLength: 30,
              autoComplete: "off",
            }}
          />
        </div>

        <div className="search_filter">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="testing_textfield"
              style={{
                width: "100%",
              }}
              clearable
              //   onKeyUp={SignInC}
              value={searchDob}
              onChange={(date) => searchDobHandleChange(date)}
              format="MM/dd/yyyy"
              maxDate={new Date()}
              autoOk
              label={
                <Typography variant="inherit" component="span">
                  DOB <span className="adjs">(MM/DD/YYYY) </span>
                </Typography>
              }
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className="status_filter">
          <div className="filter_lebel">Filter by:</div>
          <FormControl className="testing_textfield">
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select value={values} displayEmpty onChange={handleChange}>
              <MenuItem value={"Pending Review"}>Pending Review</MenuItem>
              <MenuItem value={"Level 1 Approval"}>Level 1 Approval</MenuItem>
              <MenuItem value={"Lab Approval"}>Lab Approval</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
          marginBottom: "20px",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <button className="srchbtn" onClick={SearchByValue}>
            Search
          </button>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <button className="srchbtn" onClick={Resetbyvalue}>
            Reset
          </button>
        </div>
      </div>

      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : data.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className={classes1.root}>
              <TableRow>
                <TableCell
                  className={classes1.tableRow}
                  align="center"
                ></TableCell>{" "}
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel>Submitted on (MM/DD/YYYY)</TableSortLabel>
                </TableCell>
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel>First Name</TableSortLabel>
                </TableCell>
                <TableCell className={classes1.tableRow} align="center">
                  <TableSortLabel>Last Name</TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes1.tableRow}
                  align="center"
                  style={{ width: "19vw" }}
                >
                  <TableSortLabel>DOB (MM-DD-YYYY)</TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes1.tableRow}
                  align="center"
                  style={{ width: "3vw" }}
                >
                  Status & Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length !== 0 &&
                data.map((dataItems) => {
                  return (
                    <Row
                      keyvalue={dataItems.formId}
                      key={dataItems.formId}
                      dataItems={dataItems}
                      setloader={setloader}
                      expandedId={expandedId}
                      setExpandedId={setExpandedId}
                      handleExpandClick={handleExpandClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {totalcount > 10 ? (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalcount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : (
            ""
          )}
        </TableContainer>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} align="center">
            <Typography>No Records Found</Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default TableContent;
