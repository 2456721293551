import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import lockicon from "../../Assets/lock_icon.png";
// import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import close from "../../Assets/close.png";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header.js";
import * as API from "../../Service/API/index.js";

import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

const Loginotp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [getOTP, setOTP] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const [LoginAlertopen, SetLoginAlertopen] = React.useState(false);
  const [Password, setPassword] = React.useState("");
  const [pageType, setPageType] = React.useState("");
  const [forgetQues, setforgetQues] = React.useState(false);
  //Form Initialization
  const initialFormData = Object.freeze({
    email: "",
  });

  const [formstate, setformState] = React.useState(initialFormData);

  const handleChangeForm = (event) => {
    const name = event.target.name;
    setformState({
      ...formstate,
      [name]: event.target.value,
    });
  };

  // useEffect(() => {
  //   if (localStorage.getItem("accessToken")) {
  //   } else {
  //     history.push("/");
  //   }
  // }, []);

  const [quest, setQuest] = React.useState("");
  const handleChangeQuest = (event) => {
    setQuest(event.target.value);
  };

  const handleOTP = (e) => {
    setOTP(e.target.value);
  };
  const handlepassword = (e) => {
    setPassword(e.target.value);
  };
  const [OTPerror, setOTPerror] = React.useState(false);
  const [OTPreserror, setOTPreserror] = React.useState(false);

  const SubmitOTP = (e) => {
    if (getOTP == "") {
      setOTPerror(true);
    } else {
      setloader(true);
      let data = {
        email: props.location.state.email,
        otp: getOTP,
      };
      if (props.location.state.from === "login") {
        setPageType("login");
      } else if (props.location.state.from === "signup") {
        setPageType("signup");
      } else if (props.location.state.from === "retrival") {
        setPageType("retrival");
      }

      API.ValidateOTPAPI(data, (res) => {
        console.log("res", res);
        if (res.accessToken) {
          localStorage.setItem("accessToken", res.accessToken);
          localStorage.setItem("refreshToken", res.refreshToken);
          localStorage.setItem("features", res.features);
          console.log("features", res.features);
          localStorage.setItem("labids", res.labIds);
          localStorage.setItem("states", res.states);
          localStorage.setItem("country", res.countries);
          // console.log("features", res.features.includes(""))

          if (res.pdfUrl1 != "" && res.pdfUrl2 != "") {
            localStorage.setItem("pdfUrl1", res.pdfUrl1);
            localStorage.setItem("pdfUrl2", res.pdfUrl2);
            let data = {
              page: "login",
            };
            history.push({
              pathname: "/pdf-view1",
              state: data,
            });
            setloader(false);
          } else if (
            localStorage.getItem("features").indexOf("Vax Patient Module") >= 0
          ) {
            history.push({
              pathname: "/existing-patient",
            });
            setloader(false);
          } else if (
            localStorage.getItem("features").indexOf("Vax Form Module") >= 0
          ) {
            history.push({
              pathname: "/new-patient",
            });
            setloader(false);
          }

          else if (
            localStorage.getItem("features").indexOf("Vax Missing Dose Module") >= 0
          ) {
            history.push({
              pathname: "/missing-dose",
            });
            setloader(false);
          }
          else if (
            localStorage.getItem("features").indexOf("Approval Module(edit)") || localStorage.getItem("features").indexOf("Approval Module(view)") >= 0
          ) {
            history.push({
              pathname: "/approved-patient",
            });
            setloader(false);
          }

          else {
            history.push({
              pathname: "/existing-patient",
            });
            setloader(false);
          }
        } else {
          setloader(false);
          setOTPreserror(true);
          // toast.error(res.errorMessage);
        }
      });
    }
  };
  const ResendOTP = () => {
    console.log("props.location.state", props.location.state);
    setloader(true);
    let data = {
      messageId: props.location.state.res.messageId,
      email: props.location.state.email,
      messageCode: "",
    };
    API.LoginAPI(data, (res) => {
      if (res.statusCode == "200") {
        setloader(false);
        setOTPsuccess(true);
      } else {
        setloader(false);
      }
    });
  };
  const CloseReschduleAlert = () => {
    SetLoginAlertopen(false);
  };

  //signup form detaile
  const submitSignUpForm = () => {
    if (formstate.pwd == "" && formstate.pwd.length == 0) {
      // toast.error("Please enter the password");
      return 0;
    } else if (formstate.cpwd == "" && formstate.cpwd.length == 0) {
      // toast.error("Please enter the confirmation password");
      return 0;
    } else if (
      formstate.pwd !== "" &&
      formstate.cpwd !== "" &&
      formstate.pwd !== formstate.cpwd
    ) {
      // toast.error("Password & confirmation password is Not matched");
      return 0;
    } else if (quest == "") {
      // toast.error("Please Select the Question");
      return 0;
    } else if (formstate.Answer == "") {
      // toast.error("Please enter the Answer");
      return 0;
    } else if (formstate.ssn == "" && formstate.ssn.length == 0) {
      // toast.error("Please enter SSN number");
      return 0;
    } else {
      let formData = {
        password: formstate.pwd,
        confirmPassword: formstate.cpwd,
        Answer: formstate.Answer,
        ssnNumber: formstate.ssn,
      };
      console.log(formData);
      history.push({
        pathname: "/home",
        state: formData,
      });
    }
  };
  const OpenQues = () => {
    setforgetQues(true);
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      SubmitOTP();
    }
  };
  const [OTPsuccess, setOTPsuccess] = React.useState(false);
  return (
    <>
      {/* <ToastContainer /> */}
      <Header />

      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="loginMode">
            <Grid item xs={12} md={12} sm={12}>
              <img src={lockicon} className="lockicon" />
            </Grid>

            <p>Enter the verification code we just sent to your email</p>

            <div className="otpText">
              <Grid item xs={12} md={12} sm={12}>
                <div style={{ textAlign: "center" }}>
                  <TextField
                    className="optinput"
                    id="standard-basic"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    style={{
                      textAlign: "center",
                      width: "60%",
                    }}
                    value={getOTP}
                    onChange={handleOTP}
                    onKeyUp={SignInC}
                  />
                </div>
              </Grid>
              {OTPerror ? (
                <div
                  className="errormess"
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  Please enter OTP
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="resend">
              <Grid item xs={12} md={12} sm={12}>
                <div onClick={ResendOTP}>RESEND</div>
                {OTPsuccess ? (
                  <div
                    className="successotp"
                    style={{ textAlign: "center", marginTop: "30px" }}
                  >
                    OTP sent successfully!
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          </div>

          <div className="loginSubmitHolder">
            <Grid item xs={12} md={12} sm={12}>
              <div className="submit submitFlex">
                <button
                  className="btn loginSubmit widthadj"
                  onClick={SubmitOTP}
                >
                  Submit
                </button>
              </div>

              {OTPreserror ? (
                <div
                  className="errormess"
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  Invalid OTP found
                </div>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Loginotp;
