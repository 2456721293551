import React from "react";

import footerlogoapp from "../../Assets/apptext.png";
import footerlogo from "../../Assets/Powered Beacon.svg";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="footerHolder">
          <div className="birdlogo">
            <span>
              {" "}
              Hummingbird Health Inc. Copyright &copy;{new Date().getFullYear()}{" "}
              All rights reserved
            </span>
          </div>

          {/* <div className="footer_content">
            <div className="policy">
              <a
                href="https://www.covid19.usvi.care/polariskey-faqs"
                target="_blank"
              >
                {" "}
                FAQs
              </a>
            </div>
            <div className="policy">
              <a href="http://www.covid19.usvi.care/" target="_blank">
                {" "}
                USVI CARE
              </a>
            </div>
            <div className="policy">
              <a href="https://www.covid19usvi.com/" target="_blank">
                {" "}
                USVI Covid-19
              </a>
            </div>
            <div className="policy">
              <a
                href="https://www.covid19.usvi.care/polariskey-privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </div> */}
          <div className="copyRight">
            <span className="paddingADJF">
              <img className="footerimgadj" src={footerlogo} />
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
