import React, { useEffect } from "react";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";
import "./pdfviewer.css";
import { toast, ToastContainer } from "react-toastify";
import * as API from "../../Service/API/index.js";
import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";

// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

export default function SecondPdfview(props) {
  console.log("props", props.location);
  const classes = useStyles();
  const history = useHistory();
  const [loader, setloader] = React.useState(false);

  const initialFormData = {
    Name: "",
    Accept: "",
  };

  const [formstate, setformState] = React.useState(initialFormData);
  const handleChangeForm = (event) => {
    const name = event.target.name;
    setformState({
      ...formstate,
      [name]: event.target.value,
    });
  };

  const [accept, setAccept] = React.useState("");
  const handleChangeAccept = (event) => {
    setAccept(event.target.value);
  };

  const EnterKeynext = (e) => {
    if (e.keyCode == 13) {
      formSubmit();
    }
  };

  let date = new Date();
  let pdfDate = moment(date).format("MM/DD/yyyy");

  console.log("accept", accept);
  const [Nameerror, setNameerror] = React.useState(false);
  const [Accepterror, setAccepterror] = React.useState(false);
  const [Scrollerror, setScrollerror] = React.useState(false);
  const [Submiterror, setSubmiterror] = React.useState(false);
  const [Submitsuccess, setSubmitsuccess] = React.useState(false);

  const obj = document.getElementById("pdfScroll");
  const formSubmit = () => {
    if (formstate.Name === "" && formstate.Name.length === 0) {
      // toast.error("Please enter the name");
      setNameerror(true);
      setAccepterror(false);
      setScrollerror(false);
    } else if (accept === "" && accept.length === 0) {
      // toast.error("Please accept the Terms & Conditions");
      setNameerror(false);
      setScrollerror(false);
      setAccepterror(true);
    } else if (
      Math.round(obj.scrollTop) < obj.scrollHeight - obj.offsetHeight
    ) {
      // toast.error("Please scroll and read the pdf file");
      console.log("scroll", obj.scrollTop, obj.scrollHeight, obj.offsetHeight);
      setNameerror(false);
      setAccepterror(false);
      setScrollerror(true);
    } else {
      console.log("in....");
      setloader(true);
      setNameerror(false);
      setAccepterror(false);
      setScrollerror(false);
      let data = {
        pdfNameOne: props.location.state.Name,
        pdfNameTwo: formstate.Name,
        status: accept,
      };
      console.log("data", data);
      API.Update_PdfInfoAPI(data, (res) => {
        console.log("in1....");
        if (res.statusCode === 200) {
          setloader(false);
          setSubmitsuccess(true);
          localStorage.clear();
        } else {
          setloader(false);
          setSubmiterror(true);
        }
      });

      // localStorage.removeItem("pdf");
    }
  };
  const logoutClicked = () => {
    localStorage.clear();
    history.push({
      pathname: "/",
    });
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {props.location.state && props.location.state.page === "pdfview1" ? (
        loader === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : Submitsuccess === false ? (
          <div className="pdfcontainerHolder">
            <div className="infoaccordionbg">
              <div className="infoaccSummary">
                <Grid container xs={12} md={12} sm={12}>
                  <Grid item xs={12} md={12} sm={12}>
                    <div className="pdfcontainer" id="pdfScroll">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                        <div className="pdfbody">
                          {/* <Viewer defaultScale={1.5} fileUrl={localStorage.getItem("pdf")} /> */}
                          <Viewer
                            defaultScale={1.5}
                            fileUrl={localStorage.getItem("pdfUrl2")}
                          />
                        </div>
                      </Worker>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} sm={12} className="scrollpadding">
                {Scrollerror ? (
                  <div className="errormess" style={{ textAlign: "left" }}>
                    Please Scroll and read the pdf file
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <div className="namepadding">
              <Grid container spacing={3} xs={12} md={12} sm={12}>
                <Grid item xs={12} md={4} sm={12}>
                  <div>
                    <TextField
                      id="standard-basic1"
                      label="Name "
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={handleChangeForm}
                      name="Name"
                      value={formstate.Name}
                      inputProps={{ maxLength: 40 }}
                      onKeyUp={EnterKeynext}
                    />
                  </div>
                  {Nameerror ? (
                    <div className="errormess" style={{ textAlign: "left" }}>
                      Please enter the name
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={8}
                  sm={12}
                  style={{ paddingTop: "25px" }}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="accept"
                      value={accept}
                      onChange={handleChangeAccept}
                      onKeyUp={EnterKeynext}
                    >
                      <FormControlLabel
                        value="end"
                        control={<Radio color="primary" />}
                        label="I have read and accept the terms of the HIPAA Business Associate Addendum"
                      />
                    </RadioGroup>
                  </FormControl>

                  {Accepterror ? (
                    <div className="errormess" style={{ textAlign: "left" }}>
                      Please accept terms & conditions
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </div>

            <div className="date">Date (MM/DD/YYYY): {pdfDate}</div>
            <div className="submit">
              <button className="btn" onClick={formSubmit}>
                Submit
              </button>
            </div>
            <div className="submit">
              {Submiterror ? (
                <div
                  className="suberrormess"
                  style={{ textAlign: "left", paddingLeft: "25px" }}
                >
                  Something went wrong. Please try again later.
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="successHolder">
            <div>Thank you for submitting the Application User Agreements.</div>
            <div className="submit">
              <button className="btn" onClick={logoutClicked}>
                Logout
              </button>
            </div>
          </div>
        )
      ) : (
        window.location.replace("/")
      )}
    </>
  );
}
