import React, { useState } from 'react'

import { toast } from 'react-toastify'
import moment from 'moment'
import * as API from '../../Service/API/index.js'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Grid,
  InputLabel,
  Input,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  FormControl,
  Box,
  Collapse,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Select,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import Switch from 'react-switch'
import Closeicon from '../../Assets/close.png'
import Modal from '@material-ui/core/Modal'
import ScrollableFeed from 'react-scrollable-feed'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
// import bg_image from "../../../src/Assets/bg_image.png";
import { makeStyles } from '@material-ui/core/styles'
import tableStyle from '../../Assets/jss/tableHeaderStyle'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Slider from '../Slider/Slider'
// import ReactPanZoom from 'react-image-pan-zoom-rotate'
import PhotoSlider from './SliderComponent/PhotoSlider.js'
// import dataSlider from './SliderComponent/dataSlider'

// import { findAllByDisplayValue } from "@testing-library/react";

//Modal Box Material UI
function getModalStyle() {
  const top = 50
  const left = 50
  const height = '100vh'

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(tableStyle)

function Row({
  dataItems,
  stateSelect,
  countryList,
  labName,
  getlist,
  vaccineNames,
  setloader,
  keyvalue,
  expandedId,
  setExpandedId,
  handleExpandClick,
}) {
  const classes = useStyles()

  const {
    patientId,
    firstName,
    lastName,
    dob,
    status,
    sendTextToMobile,
    receivedBothDoses,
    notes,
    formSubmittedOn,
    showRemoveButton,
  } = dataItems

  const [StatusValue, SetstatusValue] = React.useState('')
  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cardUrl: '',
    licenceUrl: '',
  })

  const [newnumber, setNumber] = React.useState('')
  const [dobDate, setdobDate] = React.useState(null)
  const [note, setNote] = React.useState('')
  const [notesData, setNotesData] = React.useState(notes)
  const [Modalopen, modalsetOpen] = React.useState(false)
  const [modalStyle] = React.useState(getModalStyle)
  const [Stateloader, setStateloader] = React.useState(false)
  const [removeDoseIds, setremoveDoseIds] = React.useState([])
  const vaccinearray = dataItems.vaccinationDoseList.map(
    ({ doseDate, stateList, ...rest }) => ({
      doseDate: doseDate ? moment(doseDate) : null,
      stateList: Object.entries(stateList),
      ...rest,
    }),
  )

  const [vaccineDoseList, setvaccineDoseList] = React.useState(vaccinearray)

  //multiple vaxCard URl state
  const [vaxCardurl, setVaxCardurl] = useState(dataItems.vaxCardURLs)

  //Multiple photoId url
  const [photoIdurl, setPhotIdurl] = useState(dataItems.photoIdURLs)

  //Error states
  const [firstNameError, setfirstNameError] = React.useState(false)
  const [lastNameError, setlastNameError] = React.useState(false)
  const [dobError, setdobError] = React.useState(false)
  const [mobilenumberError, setmobilenumberError] = React.useState(false)
  const [emailError, setemailError] = React.useState(false)
  const [emailFormetError, setemailFormetError] = React.useState(false)
  const [vaccinationError, setvaccinationError] = React.useState(false)
  const [vaccinationdateError, setvaccinationdateError] = React.useState(false)
  const [lotError, setlotError] = React.useState(false)
  const [stateError, setstateError] = React.useState(false)
  const [locationError, setlocationError] = React.useState(false)
  const [OtherlocationError, setOtherlocationError] = React.useState(false)
  const [countryError, setcountryError] = React.useState(false)

  const [changeValue, setchangeValue] = React.useState(
    localStorage.getItem('features'),
  )

  // Toogle switch
  const [getcheckedA, setcheckedA] = React.useState(true)
  const handleChangeText = (event) => {
    setcheckedA(!getcheckedA)
  }

  //Onchange functionalities
  const onChange = (e) => {
    let value = e.target.value.replaceAll('"', "'")
    setDetails({ ...details, [e.target.name]: value.replaceAll("''", "'") })
  }

  const mobileNumberChange = (number) => {
    setNumber(number)
  }

  const handleDobChange = (date) => {
    setdobDate(date)
  }

  const handleNoteChange = (e) => {
    setNote(e.target.value)
  }

  const handleInputChange = (e, index, field) => {
    setfirstNameError(false)
    setlastNameError(false)
    setdobError(false)
    setmobilenumberError(false)
    setemailError(false)
    setemailFormetError(false)
    setvaccinationError(false)
    setvaccinationdateError(false)
    setlotError(false)
    setcountryError(false)
    setstateError(false)
    setlocationError(false)
    setOtherlocationError(false)

    if (field === 'doseDate') {
      const list = [...vaccineDoseList]

      list[index]['doseDate'] = e === null ? null : moment(e)
      setvaccineDoseList(list)
    } else if (e.target.value && field === 'vaccineName') {
      const { name, value } = e.target
      const list = [...vaccineDoseList]
      list[index][name] = value
      setvaccineDoseList(list)
    } else if (field === 'lot') {
      const name = e.target.name
      const value = e.target.value.replaceAll('"', "'")
      const list = [...vaccineDoseList]
      list[index][name] = value.replaceAll("''", "'")
      setvaccineDoseList(list)
    } else if (e.target.value && field === 'state') {
      const { name, value } = e.target
      const list = [...vaccineDoseList]
      list[index][name] = value
      setvaccineDoseList(list)
    } else if (field === 'country') {
      const { name, value } = e.target
      const list = [...vaccineDoseList]
      list[index][name] = value
      setvaccineDoseList(list)
      setStateloader(true)
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          }
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              const list = [...vaccineDoseList]
              list[index]['state'] = ''
              let allEntries = Object.entries(res.info.states)
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1,
              )
              list[index]['stateList'] = sortedEntries
              setStateloader(false)
            }
          })
        }
      })
      setvaccineDoseList(list)
    } else if (e.target.value && field === 'location') {
      const { name, value } = e.target
      let labidvalue = name.split('?')[0]
      let labname = name.split('?')[1]
      const lablocation = labName.filter((item) => {
        return item.labId == value
      })
      const list = [...vaccineDoseList]
      list[index][labidvalue] = value
      list[index][labname] = lablocation[0].organizationName
      setvaccineDoseList(list)
    } else if (field === 'otherLabName') {
      const name = e.target.name
      const value = e.target.value.replaceAll('"', "'")
      const list = [...vaccineDoseList]
      list[index][name] = value.replaceAll("''", "'")
      setvaccineDoseList(list)
    }
  }

  //===Add new dose functionalities===//
  const handleAddClick = (e) => {
    setfirstNameError(false)
    setlastNameError(false)
    setdobError(false)
    setmobilenumberError(false)
    setemailError(false)
    setemailFormetError(false)
    setvaccinationError(false)
    setvaccinationdateError(false)
    setlotError(false)
    setcountryError(false)
    setstateError(false)
    setlocationError(false)
    setOtherlocationError(false)

    e.preventDefault()
    let arr = vaccineDoseList.map((item) => {
      return {
        vaccineName: item.vaccineName,
        doseDate: moment(item.doseDate).format('yyyy-MM-DD'),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        otherLabName: item.location === 'Other' ? item.otherLabName.trim() : '',
        vaccinationInfoId: item.vaccinationInfoId,
        stateList: item.stateList,
        newDose: item.newDose,
      }
    })

    let filter_data = arr.filter(function (x) {
      if (x.vaccineName != '') {
        setvaccinationError(false)
        return true
      } else if (x.doseDate != 'Invalid date' || x.doseDate != null) {
        setvaccinationdateError(false)
        return true
      } else if (x.lot.trim() != '') {
        setlotError(false)
        return true
      } else if (x.country != '') {
        setcountryError(false)
        return true
      } else if (x.state != '') {
        setstateError(false)
        return true
      } else if (x.labId != ' ') {
        setlocationError(false)
        return true
      } else if (x.location != '') {
        return true
      } else if (x.location != 'Other' && x.otherLabName.trim() != '') {
        setOtherlocationError(false)
        return true
      }
    })
    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccineName == '') {
        setvaccinationError(true)
        return true
      } else if (x.doseDate === 'Invalid date' || x.doseDate === null) {
        setvaccinationdateError(true)
        return true
      } else if (x.lot.trim() == '') {
        setlotError(true)
        return true
      } else if (x.country == '') {
        setcountryError(true)
        return true
      } else if (x.state == '') {
        setstateError(true)
        return true
      } else if (x.labId == '') {
        setlocationError(true)
        return true
      } else if (x.location == '') {
        return true
      } else if (x.location == 'Other' && x.otherLabName.trim() == '') {
        setOtherlocationError(true)
        return true
      }
    })
    if (Checkfilter_data.length === 0) {
      setvaccineDoseList([
        ...arr,
        {
          vaccineName: '',
          doseDate: null,
          lot: '',
          state: '',
          labId: '',
          location: '',
          otherLabName: '',
          vaccinationInfoId: 0,
          country: '',
          stateList: [],
          newDose: true,
        },
      ])

      setvaccinationError(false)
      setvaccinationdateError(false)
      setlotError(false)
      setcountryError(false)
      setstateError(false)
      setlocationError(false)
      setOtherlocationError(false)
    }
  }

  //------onClick Form Update--------//
  const updateData = (e, patientId) => {
    e.preventDefault()
    setfirstNameError(false)
    setlastNameError(false)
    setdobError(false)
    setmobilenumberError(false)
    setemailError(false)
    setvaccinationError(false)
    setvaccinationdateError(false)
    setlotError(false)
    setcountryError(false)
    setstateError(false)
    setlocationError(false)
    setOtherlocationError(false)

    let arr = vaccineDoseList.map((item) => {
      return {
        vaccinationType: item.vaccineName,
        date: moment(item.doseDate).format('yyyy-MM-DD'),
        location: item.location,
        labId: item.labId,
        lot: item.lot.trim(),
        country: item.country,
        state: item.state,
        otherLabName: item.location === 'Other' ? item.otherLabName.trim() : '',
        vaxInfoId: item.vaccinationInfoId,
      }
    })

    if (arr.length === 0) {
      toast.error('Please add atleast one dose before save')
    }

    let filter_data = arr.filter(function (x) {
      if (x.vaccinationType != '') {
        return true
      } else if (x.date != 'Invalid date' || x.date != null) {
        return true
      } else if (x.lot.trim() != '') {
        return true
      } else if (x.country != '') {
        return true
      } else if (x.state != '') {
        return true
      } else if (x.labId != ' ') {
        return true
      } else if (x.location != '') {
        return true
      } else if (x.location != 'Other' && x.otherLabName.trim() != '') {
        return true
      }
    })
    let Checkfilter_data = arr.filter(function (x) {
      if (x.vaccinationType == '') {
        return true
      } else if (x.date === 'Invalid date' || x.date === null) {
        return true
      } else if (x.lot.trim() == '') {
        return true
      } else if (x.country == '') {
        return true
      } else if (x.state == '') {
        return true
      } else if (x.labId == '') {
        return true
      } else if (x.location == '') {
        return true
      } else if (x.location == 'Other' && x.otherLabName.trim() == '') {
        return true
      }
    })

    if (details.firstName.trim() === '') {
      setfirstNameError(true)
    } else if (details.lastName.trim() === '') {
      setlastNameError(true)
    } else if (dobDate === 'Invalid date' || dobDate === null) {
      setdobError(true)
    } else if (newnumber.length < 2) {
      setmobilenumberError(true)
    } else if (details.email.trim() === '') {
      setemailError(true)
    } else if (
      details.email.trim() !== 'N/A' &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        details.email.trim(),
      ) &&
      details.email.trim() != ''
    ) {
      setemailFormetError(true)
    } else if (vaccineDoseList.length) {
      for (let i = 0; i < vaccineDoseList.length; i++) {
        if (vaccineDoseList[i].vaccineName === '') {
          setvaccinationError(true)
        } else if (
          vaccineDoseList[i].doseDate === 'Invalid date' ||
          vaccineDoseList[i].doseDate === null
        ) {
          setvaccinationdateError(true)
        } else if (vaccineDoseList[i].lot.trim() === '') {
          setlotError(true)
        } else if (vaccineDoseList[i].country === '') {
          setcountryError(true)
        } else if (vaccineDoseList[i].state === '') {
          setstateError(true)
        } else if (vaccineDoseList[i].labId === '') {
          setlocationError(true)
        } else if (
          vaccineDoseList[i].labId === 32 &&
          vaccineDoseList[i].otherLabName.trim() === ''
        ) {
          setOtherlocationError(true)
        }
      }
      if (Checkfilter_data.length === 0) {
        console.log('>>>>>>> API call >>>>>>>>>>')
        setloader(true)
        let data = {
          patientId: patientId,
          firstName: details.firstName.trim(),
          lastName: details.lastName.trim(),
          email: details.email.trim(),
          dob: moment(dobDate).format('yyyy-MM-DD'),
          mobileNumber: newnumber.includes('+') ? newnumber : '+' + newnumber,
          status,
          sendTextToMobile,
          vaxInfos: filter_data,
          idsToDelete: removeDoseIds,
        }
        console.log('data', data)
        API.EXIST_UPDATE_FORM_URL(data, (res) => {
          if (res.statusCode === 200) {
            toast.success('Patient details updated successfully')
            setExpandedId(-1)
            window.scrollTo(0, 0)
            getlist()
          } else {
            if (res.info) {
              toast.error(res.info)
            } else {
              toast.error(res.errorMessage)
            }
          }
        })
      }
    }
  }

  const resendNotifiy = (e, patientId) => {
    e.preventDefault()
    setloader(true)
    let data = {
      patientId: patientId,
    }
    API.RESEND_NOTIFICATION(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false)
        setExpandedId(-1)
        window.scrollTo(0, 0)
        toast.success('PolarisKey Successfully Resent')
      } else {
        setloader(false)
        setExpandedId(-1)
        window.scrollTo(0, 0)
        toast.error('Polariskey Resent failed')
      }
    })
  }

  //------onSelect--------//
  const onSelect = (id, keyvalue) => {
    handleExpandClick(keyvalue)
    if (id) {
      setDetails({
        ...details,
        ...dataItems,
      })

      console.log("data", dataItems)
      setremoveDoseIds([])
      setvaccineDoseList(vaccinearray)
      setNote('')
      setNumber(dataItems.mobileNumber)
      setdobDate(moment(dataItems.dob))
    }
  }

  //------onClick ADD--------//
  const AddNote = (e, id) => {
    e.preventDefault()
    const newItem = {
      patientId: id,
      notes: note.trim(),
    }

    if (newItem.notes != '') {
      API.EXISTING_ADD_NOTE_URL(newItem, (res) => {
        if (res.statusCode === 200) {
          toast.success(res.info)
        } else {
          toast.error(res.info)
        }
      })
      const newData = [...notesData, newItem]
      setNotesData(newData)
      setNote('')
    }
  }

  const handleRemoveClick = (index, newDose) => {
    if (newDose == true) {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDummyDoseconfirmfun(index),
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun(),
          },
        ],
      })
    } else {
      confirmAlert({
        title: 'Delete Dose',
        message: `Are you sure you want to delete this dose?`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => RemoveDBDoseconfirmfun(newDose, index),
          },
          {
            label: 'Cancel',
            onClick: () => Dosecancelfun(),
          },
        ],
      })
    }
  }

  const RemoveDummyDoseconfirmfun = (index) => {
    const list = [...vaccineDoseList]
    list.splice(index, 1)
    setvaccineDoseList(list)
  }

  const Dosecancelfun = () => {
    console.log('cancel clicked')
  }

  const RemoveDBDoseconfirmfun = (newDoseVal, formIDvalue) => {
    console.log('===API CALL CAN RUN===')
    const itratevalue = [...removeDoseIds, newDoseVal]
    setremoveDoseIds(itratevalue)
    console.log('itratevalue', itratevalue)

    const list = [...vaccineDoseList]
    list.splice(formIDvalue, 1)
    setvaccineDoseList(list)
  }

  //===Status update functionalities===//
  const handleStatus = (e) => {
    setloader(true)
    SetstatusValue(e.target.value)
    let data = {
      status: e.target.value,
      patientId: patientId,
    }
    API.ExistingStatusAPI(data, (res) => {
      setExpandedId(-1)
      getlist()
      toast.success('Status updated successfully')
    })
  }
  const handleClose = () => {
    modalsetOpen(false)
  }
  const [getImg, setImg] = React.useState('')

  const handleOpen = (imgURL) => {
    // console.log("imgURL", imgURL);
    if (imgURL != '') {
      setImg(imgURL)
      modalsetOpen(true)
    }
  }

  const body = (
    <div
      style={modalStyle}
      className="paperModal"
      onContextMenu={(e) => {
        e.preventDefault()
      }}
    >
      <div className="closeHolder" onClick={handleClose}>
        <img src={Closeicon} alt="close-icon" />
      </div>
      {getImg.split('.').pop().split(/\#|\?/)[0].toLowerCase() === 'pdf' ? (
        <div className="pdfViewModal">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div>
              <Viewer defaultScale={0.5} fileUrl={getImg} />
            </div>
          </Worker>
        </div>
      ) : (
        <div className="modal_image_preview">
          <img
            src={getImg}
            alt="modal-view"
            draggable="false"
            onContextMenu={(e) => {
              e.preventDefault()
            }}
          />
        </div>
      )}
    </div>
  )

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <TableCell
          onClick={() => onSelect(patientId, keyvalue)}
          style={{ cursor: 'pointer' }}
        >
          {(expandedId === keyvalue) == false ? (
            <NavigateNextIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {formSubmittedOn ? moment(formSubmittedOn).format('MM-DD-yyyy') : ''}
        </TableCell>

        <TableCell
          style={{ cursor: 'pointer' }}
          align="center"
          component="th"
          scope="row"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {firstName}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {lastName}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          align="center"
          onClick={() => onSelect(patientId, keyvalue)}
        >
          {moment(dob).format('MM-DD-yyyy')}
        </TableCell>

        <TableCell align="center">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              value={StatusValue ? StatusValue : status}
              disabled={changeValue.includes("Approval Module(view)") ? true : false}
              // disabled={changeValue.includes("Approval Module(view)") ? true : false}
              onChange={handleStatus}
              inputProps={{
                name: 'Status',
                id: 'outlined-age-native-simple',
              }}
              className="select_res"
            >
              <option aria-label="None" value="" />
              <option value={"Approved/VAMS Pending"}>Approved/VAMS Pending</option>
              <option value={"Approved/VAMS Updated"}>Approved/VAMS Updated</option>
              <option value={"Document Approval"}>Document Approval</option>
              <option value={"ID Verified/Registry Match"}>ID Verified/Registry Match</option>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expandedId === keyvalue} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <form className={classes.root1} autoComplete="off">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container spacing={1}>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="First name *"
                        type="text"
                        value={details.firstName}
                        name="firstName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: 'none',
                        }}
                      />

                      {firstNameError === true &&
                      details.firstName.trim() === '' ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the firstname
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="Last name *"
                        type="text"
                        value={details.lastName}
                        name="lastName"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: 'none',
                        }}
                      />

                      {lastNameError === true &&
                      details.lastName.trim() === '' ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the lastName
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{
                            width: '100%',
                          }}
                          name="dob"
                          clearable
                          value={dobDate}
                          onChange={(date) => handleDobChange(date)}
                          format="MM/dd/yyyy"
                          label="DOB (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                        />
                      </MuiPickersUtilsProvider>

                      {dobError === true && dobDate === null ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the date of birth
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          paddingBottom: '0.3em',
                          fontSize: '13px',
                        }}
                      >
                        Mobile number:
                      </InputLabel>
                      <PhoneInput
                        country={'us'}
                        onlyCountries={['us']}
                        countryCodeEditable={false}
                        required
                        name="mobileNumber"
                        disableDropdown={true}
                        onChange={mobileNumberChange}
                        value={newnumber}
                      />
                      {mobilenumberError === true ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the Mobile Number
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>

                    <Grid
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <Switch
                        checked={getcheckedA}
                        onChange={handleChangeText}
                        name="getcheckedA"
                      />
                      <span
                        style={{
                          color: '#808080',
                          marginLeft: '10px',
                          fontSize: '15px',
                        }}
                      >
                        Can this number receive text messages?
                      </span>
                    </Grid>

                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={4}
                      sm={12}
                    >
                      <TextField
                        label="Email *"
                        type="text"
                        value={details.email}
                        name="email"
                        onChange={onChange}
                        inputProps={{
                          maxLength: 30,
                          autoComplete: 'none',
                        }}
                      />

                      {emailError === true && details.email.trim() === '' ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the Email
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {emailFormetError === true ? (
                        <div
                          className="errorText"
                          style={{ marginTop: '20px' }}
                        >
                          Please enter the valid email
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems:'center',
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{ textAlign: 'center' }}
                          className="span_text"
                        >
                          Photo ID:
                        </div>
                        <div>
                        {photoIdurl.length ? (
                            <PhotoSlider data={photoIdurl} getlist={getlist} />
                          ) : (
                            <div></div>
                          )}
                          {/* {details.licenceUrl ? (
                            ['jpeg', 'png', 'jpg', 'heif', 'pdf'].includes(
                              details.licenceUrl
                                .split('.')
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase(),
                            ) ? (
                              details.licenceUrl
                                .split('.')
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === 'pdf' ? (
                                <div>
                                  <Button
                                    style={{
                                      background: '#f07167',
                                      color: '#fff',
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.licenceUrl)
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  src={details.licenceUrl}
                                  onClick={(e) => {
                                    handleOpen(details.licenceUrl)
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              )
                            ) : (
                              // <a
                              //   style={{ color: "#f07167" }}
                              //   href={details.licenceUrl}
                              //   target="_blank"
                              // >
                              //   Open the file
                              // </a>
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )} */}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems:'center',
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          className="span_text"
                          style={{ textAlign: 'center' }}
                        >
                          VAX Record Photo:
                        </div>
                        <div>
                          {vaxCardurl.length ? (
                            <Slider data={vaxCardurl} getlist={getlist} />
                          ) : (
                            <div></div>
                          )}
                          {/* {details.cardUrl ? (
                            ["jpeg", "png", "jpg", "heif", "pdf"].includes(
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase()
                            ) ? (
                              details.cardUrl
                                .split(".")
                                .pop()
                                .split(/\#|\?/)[0]
                                .toLowerCase() === "pdf" ? (
                                <div>
                                  <Button
                                    style={{
                                      background: "#f07167",
                                      color: "#fff",
                                    }}
                                    color="secondary"
                                    startIcon={<PictureAsPdfIcon />}
                                    onClick={(e) => {
                                      handleOpen(details.cardUrl);
                                    }}
                                  >
                                    View PDF
                                  </Button>
                                </div>
                              ) : (
                                <img
                                  className="preview-img"
                                  src={details.cardUrl}
                                  onClick={(e) => {
                                    handleOpen(details.cardUrl);
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )
                            ) : (
                              <span>Unsupported format</span>
                            )
                          ) : (
                            <div></div>
                          )} */}
                        </div>
                      </div>
                    </Grid>
                    {vaccineDoseList.map((dose, i) => {
                      return (
                        <>
                          <Paper
                            elevation={4}
                            style={{
                              width: '100%',
                              padding: '20px',
                              margin: '5px 0px',
                            }}
                          >
                            <Grid container spacing={3}>
                              {showRemoveButton == true ||
                              dose.newDose == true ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  style={{ padding: '0px' }}
                                >
                                  {changeValue.includes(
                                    'Approval Module(view)',
                                  ) ? null : (
                                    <div className="removeDose">
                                      <span
                                        className="removebtn"
                                        onClick={() =>
                                          handleRemoveClick(
                                            i,
                                            dose.newDose
                                              ? dose.newDose
                                              : dose.vaccinationInfoId,
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          style={{
                                            width: '20px',
                                            marginRight: '5px',
                                          }}
                                        />
                                        Remove
                                      </span>
                                    </div>
                                  )}
                                </Grid>
                              ) : null}
                              <Grid
                                style={{ marginTop: '20px' }}
                                item
                                xs={12}
                                md={6}
                                sm={12}
                              >
                                <FormControl
                                  className={classes.formControl}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    <span style={{ marginRight: '10px' }}>
                                      {i + 1}
                                      {i + 1 == 1
                                        ? 'st'
                                        : i + 1 == 2
                                        ? 'nd'
                                        : i + 1 == 3
                                        ? 'rd'
                                        : 'th'}
                                    </span>
                                    Vaccination Name
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="vaccineName"
                                    value={dose.vaccineName}
                                    onChange={(e) =>
                                      handleInputChange(e, i, 'vaccineName')
                                    }
                                    required
                                  >
                                    {vaccineNames.map((item) => (
                                      <MenuItem value={item.vaccineName}>
                                        {item.vaccineName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                {vaccinationError === true &&
                                dose.vaccineName === '' ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: '20px' }}
                                  >
                                    Please select Vaccination name
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>

                              <Grid
                                style={{ marginTop: '23px' }}
                                item
                                xs={12}
                                md={6}
                                sm={12}
                              >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    style={{
                                      width: '100%',
                                    }}
                                    clearable
                                    name="doseDate"
                                    value={dose.doseDate}
                                    onChange={(e) =>
                                      handleInputChange(e, i, 'doseDate')
                                    }
                                    format="MM/dd/yyyy"
                                    label="Vaccination date (MM/DD/YYYY)"
                                    maxDate={new Date()}
                                    required
                                    autoOk
                                  />
                                </MuiPickersUtilsProvider>

                                {vaccinationdateError === true &&
                                dose.doseDate === null ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: '20px' }}
                                  >
                                    Please select Vaccination date
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              <Grid
                                style={{ marginTop: '20px' }}
                                item
                                xs={12}
                                md={6}
                                sm={12}
                              >
                                <TextField
                                  label="Lot # *"
                                  type="text"
                                  value={dose.lot}
                                  name="lot"
                                  onChange={(e) =>
                                    handleInputChange(e, i, 'lot')
                                  }
                                />
                                {lotError === true && dose.lot.trim() === '' ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: '20px' }}
                                  >
                                    Please enter the LOT no
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sm={12}
                                style={{ marginTop: '18px' }}
                              >
                                <FormControl
                                  className="form-dropdown"
                                  fullWidth
                                  variant="standard"
                                >
                                  <InputLabel>Country *</InputLabel>
                                  <Select
                                    name="country"
                                    value={dose.country}
                                    onChange={(e) =>
                                      handleInputChange(e, i, 'country')
                                    }
                                    required
                                  >
                                    {countryList.map((item) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.countryCode}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {countryError === true &&
                                dose.country === '' ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: '20px' }}
                                  >
                                    Please select the Country
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              {Stateloader == true ? (
                                <div className="stateLoader">
                                  <CircularProgress />
                                </div>
                              ) : (
                                <Grid
                                  style={{ marginTop: '18px' }}
                                  item
                                  xs={12}
                                  md={6}
                                  sm={12}
                                >
                                  <FormControl
                                    className={classes.formControl}
                                    style={{ width: '100%' }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Location *
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="state"
                                      value={dose.state}
                                      onChange={(e) =>
                                        handleInputChange(e, i, 'state')
                                      }
                                      required
                                    >
                                      {dose.stateList &&
                                        dose.stateList.map((item) => (
                                          <MenuItem
                                            value={
                                              item[1].stateCode
                                                ? item[1].stateCode
                                                : item[0]
                                            }
                                          >
                                            {item[0] !== 'other'
                                              ? item[0]
                                              : 'Outside of U.S'}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                  {stateError === true && dose.state === '' ? (
                                    <div
                                      className="errorText"
                                      style={{ marginTop: '20px' }}
                                    >
                                      Please select location.
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid>
                              )}
                              <Grid
                                style={{ marginTop: '20px' }}
                                item
                                xs={12}
                                md={6}
                                sm={12}
                              >
                                <FormControl
                                  className={classes.formControl}
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Site *
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="labId?location"
                                    value={dose.labId}
                                    onChange={(e) =>
                                      handleInputChange(e, i, 'location')
                                    }
                                    required
                                  >
                                    {labName.map((item) => (
                                      <MenuItem value={item.labId}>
                                        {item.organizationName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {locationError === true && dose.labId === '' ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: '20px' }}
                                  >
                                    Please select the site.
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>

                              {dose.labId === 32 && (
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sm={12}
                                  style={{ marginTop: '22px' }}
                                >
                                  <TextField
                                    value={dose.otherLabName}
                                    id="standard-basic"
                                    name="otherLabName"
                                    label="Please specify site location *"
                                    onChange={(e) =>
                                      handleInputChange(e, i, 'otherLabName')
                                    }
                                    inputProps={{
                                      maxLength: 30,
                                      autoComplete: 'none',
                                    }}
                                  />

                                  {OtherlocationError === true &&
                                  dose.otherLabName.trim() === '' ? (
                                    <div
                                      className="errorText"
                                      style={{ marginTop: '20px' }}
                                    >
                                      Please enter the site name
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid>
                              )}
                              {dose.labId === 32 ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sm={12}
                                  style={{
                                    marginTop: '20px',
                                    paddingTop: '15px',
                                  }}
                                >
                                  <span className="span_text">Site phone:</span>
                                  {dose.sitePhoneNumber}
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  style={{
                                    marginTop: '20px',
                                    paddingTop: '15px',
                                  }}
                                >
                                  <span className="span_text">Site phone:</span>
                                  {dose.sitePhoneNumber}
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </>
                      )
                    })}
                    <Grid
                      style={{ marginTop: '20px', paddingLeft: '20px' }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <span className="span_text">Notes:</span>

                      <FormControl>
                        <InputLabel htmlFor="my-input">Add notes</InputLabel>
                        <Input
                          label="Add notes"
                          type="text"
                          value={note}
                          name="Add notes"
                          onChange={handleNoteChange}
                          endAdornment={
                            <IconButton
                              type="submit"
                              className={classes.iconButton}
                              onClick={(e) => AddNote(e, patientId)}
                            >
                              <AddBoxIcon style={{ color: '#4cd3c5' }} />
                            </IconButton>
                          }
                        />
                      </FormControl>
                      {notesData.length !== 0 && (
                        <div
                          id="list"
                          style={{
                            height: '200px',
                          }}
                        >
                          <ScrollableFeed>
                            {notesData.map((noteitems) => {
                              return (
                                <ListItem>
                                  <ListItemText className="listItems_data">
                                    {noteitems.note
                                      ? noteitems.note
                                      : noteitems.notes}
                                  </ListItemText>
                                </ListItem>
                              )
                            })}
                          </ScrollableFeed>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      style={{ marginTop: '20px' }}
                      item
                      xs={12}
                      md={6}
                      sm={12}
                    >
                      <span className="span_text">Status Update:</span>
                      <div
                        style={{
                          marginTop: '10px',
                          height: '200px',
                        }}
                      >
                        <ScrollableFeed>
                          {dataItems.audits.map((auditItem) => {
                            return (
                              <ListItem>
                                <ListItemText className="listItems_data">
                                  {auditItem.adminName} updated{' '}
                                  {auditItem.status} at{' '}
                                  {moment(auditItem.date).format('MM-DD-yyyy')}
                                </ListItemText>
                              </ListItem>
                            )
                          })}
                        </ScrollableFeed>
                      </div>
                    </Grid>
                  </Grid>
                  {changeValue.includes('Approval Module(view)') ? (
                    <div></div>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '25px',
                          marginBottom: '20px',
                        }}
                      >
                        <div style={{ margin: '0px 10px' }}>
                          <button onClick={handleAddClick} className="srchbtn">
                            Add Dose
                          </button>
                        </div>
                        <div style={{ margin: '0px 10px' }}>
                          <button
                            className="srchbtn"
                            onClick={(e) => updateData(e, patientId)}
                          >
                            Save
                          </button>
                        </div>
                        <div style={{ margin: '0px 10px' }}>
                          <button
                            className="srchbtn"
                            style={{ width: '170px' }}
                            onClick={(e) => resendNotifiy(e, patientId)}
                          >
                            Resend notificaion
                          </button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Modal
        open={Modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  )
}

export default Row
