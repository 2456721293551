import React from "react";
import { useHistory } from "react-router";
import "./App.css";
import Login from "./Components/Auth/Login";
import LoginOtp from "./Components/Auth/Loginotp";
import Footer from "./Components/Footer/Footer";
import SideNav from "./Components/SideNavigation/SideNav";
import Pdfview from "./Components/Pdf/PdfViewer.js";
import SecondPdfview from "./Components/Pdf/SecondPdfViewer";
import { BrowserRouter as Router } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { Route } from "react-router-dom";
import FeedbackIcon from "../src/Assets/FeedbackIcon.png";
function App() {
  let history = useHistory();
  if (
    localStorage.getItem("accessToken") !== null &&
    (window.location.pathname === "/" ||
      window.location.pathname === "/login-otp")
  ) {
    console.log("check");
    if (localStorage.getItem("features") === "Vax Form Module") {
      console.log("check New patient");

      window.location.replace("/new-patient");
    } else if (localStorage.getItem("features") === "Vax Patient Module") {
      console.log("check Exist patient");

      window.location.replace("/existing-patient");
    } else {
      window.location.replace("/existing-patient");
    }
  }

  const [sessionTimeOut, setsessionTimeOut] = React.useState(false);

  React.useEffect(() => {
    const x = document.cookie;
    if (x === "") {
      localStorage.clear();
    }
    if (sessionTimeOut === true) {
      localStorage.clear();
      window.location.replace("/");
    }
  }, [sessionTimeOut]);
  const handleOnIdle = (event) => {
    if (event === undefined) {
      setsessionTimeOut(true);
    }
  };

  const handleOnActive = (event) => { };

  const handleOnAction = (e) => {
    if (e !== undefined) {
      setsessionTimeOut(false);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <>
      <div className="mainHolder">
        <Router>
          <Route path="/" exact component={Login} />
          <Route path="/login-otp" exact component={LoginOtp} />
          <Route path="/new-patient" exact component={SideNav} />
          <Route path="/existing-patient" exact component={SideNav} />
          <Route path="/pdf-view1" exact component={Pdfview} />
          <Route path="/pdf-view2" exact component={SecondPdfview} />
          <Route path="/missing-dose" exact component={SideNav} />
          <Route path="/testing-patient" exact component={SideNav} />
          <Route path="/approved-patient" exact component={SideNav} />
        </Router>
        <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
      </div>
      <Footer />
    </>
  );
}

export default App;
