import axios from "axios";
import * as API_URL from "../API-URL/index.js";

// Auth login

export function LoginAPI(data, res) {
  axios
    .post(API_URL.LOGIN_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Auth Validate OTP
export function ValidateOTPAPI(data, res) {
  axios
    .get(API_URL.VALIDATEOTP_URL + "?otp=" + data.otp)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
    });
}

export function UpdateStatusAPI(data, res) {
  axios
    .post(
      API_URL.UPDATE_STATUS_URL +
        "?formId=" +
        data.formId +
        "&status=" +
        data.status,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

export function GETNEWPATIENT_URL(data, res) {
  axios
    .post(API_URL.GETNEWPATIENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Existing patient
export function GETEXISTINGPATIENT(data, res) {
  axios
    .post(API_URL.EXISTING_PATIENT_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// Existing patient
export function GETMISSINGDOSE_URL(data, res) {
  axios
    .post(API_URL.GETMISSINGDOSE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

export function ExistingStatusAPI(data, res) {
  axios
    .post(
      API_URL.EXISTING_STATUS_URL +
        "?status=" +
        data.status +
        "&patientId=" +
        data.patientId,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}
//Add Note
export function ADD_NOTE_URL(data, res) {
  axios
    .post(API_URL.ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Existing Add note
export function EXISTING_ADD_NOTE_URL(data, res) {
  axios
    .post(API_URL.EXISTING_ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Missing dose Add note
export function MISSINGDOSE_ADD_NOTE_URL(data, res) {
  axios
    .post(API_URL.MISSINGDOSE_ADD_NOTE_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//UPDATE_FORM_URL API
export function UPDATE_FORM_URL(data, res) {
  axios
    .post(API_URL.UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Existing Patient UPDATE_FORM_URL API
export function EXIST_UPDATE_FORM_URL(data, res) {
  axios
    .post(API_URL.EXISTING_PATIENT_UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Missing dose UPDATE_FORM_URL API
export function MISSINGDOSE_UPDATE_FORM_URL(data, res) {
  axios
    .post(API_URL.MISSINGDOSE_UPDATE_FORM_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//Missing dose status Update
export function MISSINGDOSE_STATUS_URL(data, res) {
  axios
    .post(
      API_URL.MISSINGDOSE_STATUS_URL +
        "?missingDoseStatus=" +
        data.missingDoseStatus +
        "&missingDoseFormId=" +
        data.missingDoseFormId,

      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

export function Update_PdfInfoAPI(data, res) {
  axios
    .post(API_URL.PdfInfo_URL, data, {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response);
    });
}

//Get Counties API
export function GetCounties_API(res) {
  axios
    .get(API_URL.GET_COUNTIES_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
//Get Vaccination names list
export function GetOrg_API(res) {
  axios
    .get(API_URL.GET_ORG_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function GetVaccination_API(res) {
  axios
    .get(API_URL.GET_VACCINATION_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
// Auth Refresh Token
export function RefreshTokenAPI(data, res) {
  axios
    .get(API_URL.REFRESHTOKEN_URL + "?refreshToken=" + data.refreshToken, {
      headers: {
        Authorization:
          "61c32291-e243-4c49-9d16-532d44f04d9a6ee023b4-4823-4ff0-9fc4-0f3797e7c1c1",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

// ---------------------------------------------------------------------------------------------------------
//Resend Vaccination Link
export function RESEND_NOTIFICATION(data, res) {
  axios
    .get(API_URL.RESEND_NOTIFICATION + "?patientID=" + data.patientId,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken")
        },
      })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get Countrylist API
export function GET_BVI_COUNTRYLIST(res) {
  axios
    .get(API_URL.GET_BVI_COUNTRYLIST)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get STATElist API
export function GET_BVI_STATELIST(data, res) {
  axios
    .post(API_URL.GET_BVI_STATELIST + "?countryId=" + data.countryId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//DELETE DOSE LIST API
export function DELETE_DOSE(data, res) {
  axios
    .post(
      API_URL.DELETE_DOSE +
      "?type=" +
      data.type +
      "&id=" +
      data.id,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      // if (error.response.data.errorMessage == "Token expired.") {
      //     RefreshTokenAPI(true)
      // }
    });
}

//  delete photo and vaxcard images
export function deletephotoIdandVaxcard(data, res) {
  axios
    .post(
      API_URL.DELETE_PHOTO_VAXID + '?id=' + data.id + '&from=' + data.from,
      {},
      {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response) {
        res(response.data)
      }
    })
    .catch((error) => {
      res(error.response ? error.response.data : error.response)
    })
}